import React  from 'react';
import { Translate } from 'react-redux-i18n'
import { Divider } from 'antd';
import { Link } from "react-router-dom";
const ContactUs = (props) =>{
  let buildingInfo = props.buildingInfo && props.buildingInfo.data ? props.buildingInfo.data :{};
  // console.log("buildingInfo",buildingInfo)
  return <footer className="text-muted contact-us">
    {
     !buildingInfo.loginAlphaboxFlag ?
    <div className="container">
      <p ></p>
      <div  className="row" >

        <div className="col-md-12">
          <div className="header">ติดต่อเรา / Contact Us</div>
          <Divider orientation="left"><div className="sub-header">
          <span className="msg_support_time">(<Translate value="msg_support_time"  />)</span>
          </div></Divider>
        </div>
        {/* <div className="col-md-12" style={{marginTop:10,marginBottom:10}}>
         <b  style={{color:'#fff'}}> หน้าที่เกี่ยวข้อง</b>
        <div className="link-page">
          <Link to="/usage-history" style={{color:'#fff'}}> - Usage history</Link>
        </div>
        </div> */}


        <div className="col-md-12 text-right mini-icons" style={{marginTop:20,marginBottom:20}}>
        <span className="icons">
        <img src={require('../../../../assets/icons/icon_telephone.png')} />
        <span id="telephone_number">{ buildingInfo.servicePhone?buildingInfo.servicePhone:"02-026-1922"}</span>
        </span>
        <span className="icons"><img src={require('../../../../assets/icons/icon_line@.png')} />@smartzone</span>
        <span className="icons"><img src={require('../../../../assets/images/icon_email@.png')}  />support@smartzoneonline.com</span>
        </div>
      </div>
    </div>  :
    <>
    {
      buildingInfo.servicePhone || buildingInfo.socialContact?
    <div className="container">
      <p ></p>
      <div  className="row" >

        <div className="col-md-12">
          <div className="header">ติดต่อเรา / Contact Us</div>
          <Divider orientation="left">
          </Divider>
        </div>
        <div className="col-md-12 text-left mini-icons" style={{marginBottom:8}}>
        <span className="icons">
        {buildingInfo.servicePhone?<>
        <img src={require('../../../../assets/icons/icon_telephone.png')} />
        <span id="telephone_number">{ buildingInfo.servicePhone?buildingInfo.servicePhone:"02-026-1922"}</span>
        </>:null}
        </span>
        <span className="icons">
        {buildingInfo.socialContact?<>
        <img src={require('../../../../assets/icons/icon_all.png')} />
        <span id="telephone_number">{ buildingInfo.socialContact}</span>
        </>:null}
        </span>
        </div>
      </div>
    </div>:null}

    </>
    }
  </footer>




}


export default ContactUs;
