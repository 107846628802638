

import React, { Component } from 'react';
import { Form ,Typography } from 'antd';
import Notification  from '../../../../../services/notification.service';
// import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { getCurrentLocale } from "../../../../../data/utils";
import DictionaryService  from '../../../../../i18n/DictionaryService';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Request  from '../../../../../services/request.service';
import AppConfig  from '../../../../../services/app.config';
import ReactGA from 'react-ga';
import Lens from '@material-ui/icons/Lens';
import PriorityHigh from '@material-ui/icons/ErrorOutline';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from "./../Tooltip"
import ScValidator  from '../../../../../services/ScValidator';
import moment from "moment";
import { browserName} from 'react-device-detect';
import { actions } from "../../../../../data/otpModel/action";
import { FacebookProvider, Like } from 'react-facebook';

import i18n from "../../../../../i18n/index";
import { async } from 'q';
declare let $: any;
declare let visualCaptcha: any;

const { Title } = Typography;




type TranslateProps = {
  value: any,
  locale:any
}
type TranslateState = {}
class TranslatePage extends Component<TranslateProps, TranslateState> {
  constructor(props) {
    super(props)
  }
  render() {
    let locale = "EN";
    try{
      locale = this.props.locale;
    }catch(e){

    }
    return (<span> {DictionaryService.msg(this.props.value,locale)}</span>);
  }
}

const mapStateToProps0: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps0 = {}
const Translate:any = connect<any, any, {}>(mapStateToProps0, mapDispatchToProps0)(Form.create({})(TranslatePage));



const LensChecked = withStyles({
  root: {
    color: 'rgb(248, 153, 50)',
  }
})(Lens);
const LensUnCheckedChecked = withStyles({
  root: {
    color: '#fff',
  }
})(Lens);

class Register extends Component<{enableFacebookUserLikes:any,facebookAuth:any,setRegisterMobileData:any,setOTPData:any,buildingInfo:any,changePage:any,registerUser:any,registerAction:any,form:any,submitRegister:any,submitRegisterMobile:any,locale:any},
{isCa:any,resData:any,loading:any,fields:any,formConfig:any,countryCodes:any,reCaptcha:any,occupation:any,recap1:any,recap2:any}> {
  formLogin:any;
  constructor(props) {
    super(props);
    this.state = {
      isCa:false,
      resData:[],
      loading:true,
      countryCodes:[],
      formConfig:[],
      fields:{},
      reCaptcha:"",
      occupation:[],
      recap1:'',
      recap2:''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    console.log("this.props.registerUser",this.props.registerUser)
  }

  componentDidMount() {


    Promise.all([this.getOccupation(),this.getCountryCodes()]).then(() => {
      this.getConfigForm()
    });

    AppConfig.getConfig().then((config:any)=>{
      this.setState({reCaptcha:config.reCaptcha})
    })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.locale !== this.props.locale){
      this.getConfigFormLang(nextProps.locale);
    }
  }


  getConfigForm(){
    Request.get("/register/form", {custGroupId: this.props.buildingInfo.data.custgroupId,}, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then((res: any) => {

        if (res.resultCode && res.resultCode[0] === '2') {
        let isCa =false;
        let resData = res.resultData || [];
        resData = resData.map((r)=>{
          if(r.caField === 1){
              isCa = true;
          }
          return {name:r.codeName,required:r.requiredField === 1 || r.requiredForce ===1  ? true : false};
        })
          let temp = {}
          if(this.props.registerUser.email){
            temp['email'] = Form.createFormField({
              value: this.props.registerUser.email
            })
          }
          if(this.props.registerUser.first_name){
            temp['firstName'] = Form.createFormField({
              value: this.props.registerUser.first_name
            })
          }
          if(this.props.registerUser.last_name){
            temp['surName'] = Form.createFormField({
              value: this.props.registerUser.last_name
            })
          }

          if(this.props.registerUser.mobileNo){
            temp['mobileNo'] = Form.createFormField({
              value: this.props.registerUser.mobileNo
            })
          }
          this.setState({resData:resData,isCa:isCa,
            fields: {
              data:temp
            }
          },()=>{

            this.getConfigFormLang(this.props.locale)
            this.setState({loading:false})
          })

        } else {
          Notification.error({
              message:"",
              resultCode:res.resultCode,
              description: res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
          });
          this.props.changePage("login")
        }
      })
      .catch(e => {
        console.log(e);
        let errorMessage = (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description: errorMessage,
        });
        this.props.changePage("login")
      });
  }

  getCountryCodes(){
    return Request.getOther("/json/CountryCodes.json", {}, {}).then((res: any) => {
      this.setState({countryCodes:res || []})
      return res
      })
      .catch(e => {
        return false
        console.log(e);
      });
  }


  getOccupation(){
    return Request.get("/dropdown/occupation", {}, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then((res: any) => {
        if (res.resultCode && res.resultCode[0] === '2') {//) === "20000"
          this.setState({occupation:res.resultData})
        } else {

        }
        return res
      })
      .catch(e => {
        console.log(e);
        return e
      });
  }

  async handleSubmit(data){
    console.log("handleSubmit")
    // if(!this.state.recap1 || !this.state.recap2){
    //   Notification.error({
    //     message:"",
    //     description: DictionaryService.msg("recaptcha2",this.props.locale),
    //     });
    //     return ;
    // }
    // if((this.state.recap1.toLowerCase() !== this.state.recap2.toLowerCase())){
    //   Notification.error({
    //     message:"",
    //     description: DictionaryService.msg("recaptcha2",this.props.locale),
    //   });
    //     return ;
    // }



    this.setState({loading:true})
    let s:any = {socialId:1,...this.props.registerUser};
    let d:any = {}
    if(this.props.registerUser.socialType === 'mobile'){
      d = await this.props.submitRegisterMobile({...this.props.registerUser,...data,gender:data.gender === 0 || data.gender === 1 || data.gender === "0" || data.gender === "1"? +data.gender :null,occupation:data.occupation?+data.occupation:null})
    }else{
      let emailFlag = this.props.registerUser.email?1:0;
      d = await this.props.submitRegister({socialId:1,emailFlag:emailFlag,...this.props.registerUser,...data,gender:data.gender === 0 || data.gender === 1 || data.gender === "0" || data.gender === "1"? +data.gender :null,occupation:data.occupation?+data.occupation:null})
    }

    let _data = d.data? d.data : {};
    let msg = _data.resultDescription ;
    let label = (_data.resultCode && _data.resultCode[0] === "2"?"Successfully":"Error") +" : "+_data.resultCode+" $ "+ msg;
    ReactGA.event({
      category: 'Register Page',
      action: s.socialId === 2 ? "Facebook" :"E-Register",
      label: label
    });
    console.log("ReactGA.event",{
      category: 'Register Page',
      action: s.socialId === 2? "Facebook" :"E-Register",
      label: label
    },data,d)


    if(d.resultCode === "20005"){
      if(this.props.registerUser.socialType === 'mobile'){
        this.props.setRegisterMobileData(d)
      }
      //confirmOTP

    }else if(d.type === 'success'){
      Notification.success({
        message:"",
        description: d.description,
        onCancel:()=>{
          if(browserName === 'WebKit') {
            this.setState({loading:true})
            window.location.href = window.location.origin + window.location.search +"&reload=true"
          }
        }
      });
    }else{
      if(d.description){
        let resCode = d.data?d.data.resultCode:null
        if(resCode != "40410"){
          Notification.error({
            message:"",
            description: d.description,
            resultCode: resCode
            });
        }
      }
    }
    this.setState({loading:false})
  }

  // handleSubmit(e){
  //   e.preventDefault();
  //   setTimeout(()=>{
  //     this.props.form.validateFields(async (err, values) => {
  //       if(!this.state.ReCAPTCHA){
  //         return ;
  //       }
  //       if (!err && (this.state.v_idCard.validateStatus==='success' || this.state.v_passportNo.validateStatus==='success') && ( this.props.registerAction.password === 'none' || (this.state.v_newPassword.validateStatus==='success' && this.state.v_confirmPassword.validateStatus==='success'))) {
  //         this.setState({loading:true})
  //        let d = await this.props.submitRegister({socialId:1,...this.props.registerUser,...values,gender:+values.gender,birthday:this.state.date,password:this.state.newPassword})
  //         if(d.type === 'success'){
  //           Notification.success({
  //             message:"",
  //             description: d.description,
  //           });
  //         }else{
  //           if(d.description){
  //             Notification.error({
  //               message:"",
  //               description: d.description,
  //               resultCode: d.resultCode
  //               });
  //           }
  //         }
  //         this.setState({loading:false})
  //       }
  //     });
  //   },50)
  // }




  handleFormChange = changedFields => {
    let { fields } = this.state;
    let data = { ...fields.data,...changedFields.data}
      this.setState({
        fields: {
          ...fields,
          ...changedFields,
          data:data
        },
      });
  };

  async getConfigFormLang(locale){
    let data:any = []
    let resData = this.state.resData || [];
    let list:any = [];
    console.log("resData",resData)

    let isMobile = false
    if(resData && this.props.registerUser.mobileNo){
      for (const i in resData) {
        if(resData[i].name === 'mobileNo'){
          isMobile = true
        }
      }
      if(!isMobile){
        list.push( {name:'areaCode',required: true})
        list.push( {name:'mobileNo',required: true})
      }
    }

    for (const i in resData) {
      if(resData[i].name === 'password'){
        if( this.props.registerAction.password !== 'none'){
          list.push( {name:'password',required:true})
          list.push( {name:'confirmPassword',required:true})
        }
      }else if(resData[i].name === 'name'){
        list.push( {name:'firstName',required:resData[i].required})
        list.push( {name:'surName',required:resData[i].required})
      }else if(resData[i].name === 'mobileNo' ){
        list.push( {name:'areaCode',required: this.props.registerUser.socialType === 'mobile' || resData[i].required})
        list.push( {name:'mobileNo',required: this.props.registerUser.socialType === 'mobile' ||  resData[i].required})
      }else if(resData[i].name === 'email' ){
        if(this.props.registerAction.email === 'none_required'){
          console.log("email 1",{...resData[i],required:false},this.props.registerAction);
          list.push({...resData[i],required:false})
        }else{
          list.push(resData[i])
          console.log("email 2",resData[i],this.props.registerAction);
        }



      }else{
        list.push(resData[i])
      }
    }



  let config:any = await AppConfig.getConfig();
    // if(config.name === 'local'){
    //
    // }else{
        // list.push({name:'recaptcha',required:true})
    // }
    console.log("propd",this.props)
    if(this.props.buildingInfo && this.props.buildingInfo.data &&
      this.props.buildingInfo.data.enableLikePage &&
      this.props.buildingInfo.data.fbPageRef){

      if(this.props.registerUser.appId && this.props.registerUser.socialId === 2){
          list.push({name:'facebook_like',required:true})
      }

    }



    list = list.map(r=>{
      let value:any = ''
      if(this.state.fields && this.state.fields.data && this.state.fields.data[r.name]){
        value = this.state.fields.data[r.name].value
      }else if(r.name==='birthday'){
        value = moment().add("years",-20).toDate()
      }else if(r.name==='gender'){
        value = null
      }else if(r.name==='areaCode'){
        value = "+66"
      }else if(r.name==='facebook_like'){
        //
        // if(this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.enableLikePage){
        //     value = this.props.buildingInfo.data.fbPageRef
        // }
      }

      let disabled = false
      if(this.props.registerUser.appId && r.name === "email" && this.props.registerUser.email){
        disabled = true
      }

      if(this.props.registerUser.mobileNo && (r.name === "mobileNo" )){ //||r.name ===  "areaCode"
        disabled = true
      }


      return {...r,value:value,disabled:disabled}
    })

    for(let i in list){
      data.push(this.ifTypeForm(list[i].name,list[i].required,list[i].value,list[i].disabled,locale))
    }
    this.setState({formConfig:data})
  }

  ifTypeForm(name,required,value,disabled,locale){
    if(name === 'username'){
    return {
      name : 'username',
      label : DictionaryService.msg("username",locale),
      type : 'text',
      value : value,
      disabled: disabled,
      xs:10,
      end : <Grid item  xs={1}></Grid>,
      requiredUI:required,
      start : <Grid item  xs={1}><div className="sz-icons"><div className="icon username"></div></div></Grid> ,
      rules: [
        {
          required: required,
          whitespace: true,
          message: DictionaryService.msg("vUsername",locale),
        },
      ],
      }
    }else if(name === 'password'){
     return {
        name : 'password',
        label : DictionaryService.msg("password",locale),
        type : 'password',
        value : value,
        disabled: disabled,
        xs:5,
        requiredUI:required,
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon password"></div></div></Grid> ,
        rules: [
          {
            required: true,
            whitespace: true,
            message: DictionaryService.msg("vPassword",locale),
          },
          {
            min:4,
            message: DictionaryService.msg("errOldNewPasswordMin",locale),
          },
          {
            pattern:/^[a-zA-Z0-9!@#$&()\-.+,/*_:<=>?@\[\]^`{}|~]*$/,
            message: DictionaryService.msg("errOldNewPasswordRegex",locale),
          },
          {
            validator:(res,value)=>{
              let confirmPassword =null;
              if(this.formLogin)
              confirmPassword = this.formLogin.getFieldValue("data[confirmPassword]")
              if(value === confirmPassword || !confirmPassword){
                let error = this.formLogin.getFieldError(`data[confirmPassword]`)
                if(error && error.length > 0){
                  this.formLogin.validateFields(['data[confirmPassword]'], { force: true });
                }
                return true;
              }
              return false;
            },
            message: DictionaryService.msg("vConfirmPasswordNotMatch",locale),
          }
        ],
      }
    }else if(name === 'confirmPassword'){
      return {
         name : 'confirmpassword',
         label : DictionaryService.msg("confirmPassword",locale),
         type : 'password',
         value : value,
         disabled: disabled,
         xs:5,
         end : <Grid item  xs={1}>
         <Tooltip text={()=> {
           return <>
           <h5 style={{marginBottom: 0}}> <Translate value="passwordInfo1" /> </h5>
             <Translate value="passwordInfo2" /> <br/>
             <Translate value="passwordInfo3" /> <br/>
             <Translate value="passwordInfo4" /> <br/>
             <Translate value="passwordInfo5" />
           </>
         }} style={{margin: -15,position: 'relative',top: -5}}>
         <IconButton aria-label="delete"  size="small">
               <PriorityHigh style={{fontSize: 15,color:'#fff' }}/>
         </IconButton>
         </Tooltip>
         </Grid>,
         requiredUI:required,
          rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: DictionaryService.msg("vConfirmPassword",locale),
                  },
                  {
                    min:4,
                    message: DictionaryService.msg("errOldNewPasswordMin",locale),
                  },
                  {
                    pattern:/^[a-zA-Z0-9!@#$&()\-.+,/*_:<=>?@\[\]^`{}|~]*$/,
                    message: DictionaryService.msg("errOldNewPasswordRegex",locale),
                  },
                  {
                    validator:(res,value)=>{

                      let password = null;
                      if(this.formLogin)
                      password = this.formLogin.getFieldValue("data[password]")
                      if(value === password || !password){
                        let error = this.formLogin.getFieldError(`data[password]`)
                        if(error && error.length > 0){
                          this.formLogin.validateFields(['data[password]'], { force: true });
                        }
                        return true;
                      }
                      return false;
                    },
                    message: DictionaryService.msg("vConfirmPasswordNotMatch",locale),
                  }
                ],
       }
     }else if(name === 'firstName'){
      return {
        name : 'firstName',
        label : DictionaryService.msg("firstName",locale),
        type : 'text',
        value : value,
        disabled: disabled,
        xs:5,
        requiredUI:required,
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon username"></div></div></Grid> ,
        rules: [
          {
            required: required,
            whitespace: true,
            message: DictionaryService.msg("vFirstName",locale),
          },
        ],
      }
    }else if (name === 'surName'){
      return {
        name : 'surName',
        label : DictionaryService.msg("lastName",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:5,
        requiredUI:required,
        end : <Grid item  xs={1}></Grid>,
        rules: [
          {
            required: required,
            whitespace: true,
            message: DictionaryService.msg("vLastName",locale),
          },
        ],
      }
    }
    else if (name === 'email'){
      let rules:any = [
        {
          type: 'email',
          message: DictionaryService.msg("vEmail",locale),
        },
      ]
      if(required){
        rules = [
          { required: true,
            message: DictionaryService.msg("vEmail",locale)
          },
          {
            type: 'email',
            message: DictionaryService.msg("vEmail",locale),
          },
        ]
      }
      return {
        name : 'email',
        label : DictionaryService.msg("email",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:10,
        requiredUI:required,
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon email"></div></div></Grid> ,
        end : <Grid item  xs={1}></Grid>,
        rules: rules,
      }
    } else if(name === 'citizenId'){
      return   {
          name : 'citizenId',
          label : DictionaryService.msg("idCard",locale),
          type : 'text',
          disabled: disabled,
          value : value,
          xs:10,
          requiredUIDynamic:true,
          requiredUIDynamicFuction:()=>{
            if(!required){
              return required;
            }

            if(!this.formLogin)
            return true;

            let passportNo = this.formLogin.getFieldValue("data[passportNo]")
            let citizenId = this.formLogin.getFieldValue("data[citizenId]")
            if(passportNo && !citizenId){
              return false;
            }
            return true;

          },
          start : <Grid item  xs={1}><div className="sz-icons"><div className="icon citizen"></div></div></Grid> ,
          end : <Grid item  xs={1}></Grid> ,
          rules: [
            {
              validator:(res,value)=>{

                if( this.formLogin){
                  let error = this.formLogin.getFieldError(`data[passportNo]`)
                  if(error && error.length > 0){
                    this.formLogin.validateFields(['data[passportNo]'], { force: true });
                  }
                }else{

                }

              // console.log("required",required)
              if(!required){
                return true;
              }
              let error_ = this.formLogin.getFieldError(`data[passportNo]`)
              let value_ = this.formLogin.getFieldValue(`data[passportNo]`)
              //  (`data[citizenId]`)
              if((!value || value && value.length === 0 ) && ( !value_ || error_ && error_.length > 0))
              return false;


              return true;

              },
              message: DictionaryService.msg("vPassport",locale),
            },
            {
              validator:(res,value)=>{
                if(!required){
                  if(!value || value && value.length === 0)
                  return true;

                  if(!value || value.length != 13)
                  return false;

                  let sum=0
                  for(let i=0; i < 12; i++)
                    sum += parseFloat(value.charAt(i))*(13-i);

                    if((11-sum%11)%10!=parseFloat(value.charAt(12)))
                    return false;

                  return true;
                }

                if( this.formLogin){
                  let error = this.formLogin.getFieldError(`data[passportNo]`)
                  if(error && error.length > 0){
                    this.formLogin.validateFields(['data[passportNo]'], { force: true });
                  }
                }
               if((!value || value.length === 0 )&& !this.formLogin.getFieldError(`data[passportNo]`)&& this.formLogin.getFieldValue(`data[passportNo]`)){
                  return true;
                }


                if(value && value.length === 0)
                return true;

                if(!value || value.length != 13) return false;

                let sum=0
                for(let i=0; i < 12; i++)
                  sum += parseFloat(value.charAt(i))*(13-i);

                  if((11-sum%11)%10!=parseFloat(value.charAt(12)))
                  return false;

                  return true;
              },
              message: DictionaryService.msg("vIDcard_",locale),
            }
          ],
        }
    }  else if(name === 'passportNo'){
      // console.log("passportNo:passportNo")
      return {
        name : 'passportNo',
        label : DictionaryService.msg("passport",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:10,
        requiredUIDynamic:true,
        requiredUIDynamicFuction:()=>{

          if(!required){
            return required;
          }

          if(!this.formLogin)
          return true;

          let passportNo = this.formLogin.getFieldValue("data[passportNo]")
          let citizenId = this.formLogin.getFieldValue("data[citizenId]")
          if(!passportNo && citizenId){
            return false;
          }
          return true;

        },
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon passport"></div></div></Grid> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
          {
            validator:(res,value)=>{

              if( this.formLogin){
                let error = this.formLogin.getFieldError(`data[citizenId]`)
                if(error && error.length > 0){
                  this.formLogin.validateFields(['data[citizenId]'], { force: true });
                }
              }else{

              }

            if(!required){
              return true;
            }
            let error_ = this.formLogin.getFieldError(`data[citizenId]`)
            let value_ = this.formLogin.getFieldValue(`data[citizenId]`)
            //  (`data[citizenId]`)
            if((!value || value && value.length === 0 ) && ( !value_ || error_ && error_.length > 0))
            return false;


            return true;

            },
            message: DictionaryService.msg("vPassport",locale),
          },
          {
            validator:(res,value)=>{
              if(!required){
                if(!value || value && value.length === 0)
                return true;

                var re = /[a-zA-Z]{2}[0-9]{7}/;
                if (!re.test(value)) {
                  return false;
                }
                return true;
              }

              if( this.formLogin){
                let error = this.formLogin.getFieldError(`data[citizenId]`)
                if(error && error.length > 0){
                  this.formLogin.validateFields(['data[citizenId]'], { force: true });
                }
              }

              if((!value || value.length === 0 )&& !this.formLogin.getFieldError(`data[citizenId]`)&& this.formLogin.getFieldValue(`data[citizenId]`)){
                return true;
              }

              if(value && value.length == 0)
              return true;


              var re = /[a-zA-Z]{2}[0-9]{7}/;
              if (re.test(value)) {
                return true;
              }

              return false;


            },
            message: DictionaryService.msg("vPassport_",locale),
          }
        ],
      }

    } else if(name === 'nationality'){
      return {
        name : 'nationality',
        label : DictionaryService.msg("nationality",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:10,
        requiredUI:required,
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon nationality"></div></div></Grid> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
        ],
      }
    }else if(name === 'birthday'){
      return {
        name : 'birthday',
        label : DictionaryService.msg("dateOfBirth",locale),
        type : 'date',
        disabled: disabled,
        value : value,
        xs:6,
        requiredUI:required,
        start : <>
              <Grid item  xs={1}><div className="sz-icons"><div className="icon date"></div></div></Grid>
              <Grid item  xs={4} style={{padding: "0px 8px 0px !important"}}>
              <div style={{    position: 'relative',top: -4,color:"#fff",width: 85}} className={required?"MuiTypography-body1_ rq-lable":"MuiTypography-body1_"}>
              {DictionaryService.msg("dateOfBirth",locale)}
              </div>
              </Grid>
              </> ,
         end : <Grid item  xs={1}></Grid> ,
        rules: [
          {
            required: required,
            message: DictionaryService.msg("vBirthday",locale),
          },
        ],
      }
    } else if(name === 'nationality'){
      return {
        name : 'nationality',
        label : DictionaryService.msg("nationality",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:10,
        requiredUI:false,
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon nationality"></div></div></Grid> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
        ],
      }

    }else if(name === 'occupation'){
      let occupation = this.state.occupation || []

      occupation = occupation.map((option)=>{
        let label =  option.occupationNameEn;
        if(locale==='TH'){
          label = option.occupationNameTh
        }
        return {value:option.id,label:label}
      })

      console.log("occupation",occupation)

      return {
        name : 'occupation',
        // label : DictionaryService.msg("occupation",locale),
        type : 'seletebox',
        disabled: disabled,
        value : value,
        items:occupation,
        xs:6,
        requiredUI:false,
        start : <>
              <Grid item  xs={1}><div className="sz-icons"><div className="icon occupation"></div></div></Grid>
              <Grid item  xs={4} style={{padding: "0px 8px 0px !important"}}>
              <div style={{    position: 'relative',top: -4,color:"#fff",width: 85}} className={required?"MuiTypography-body1_ rq-lable":"MuiTypography-body1_"}>
                {DictionaryService.msg("occupation",locale)}
              </div>
              </Grid>
              </> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
          {
            required: required,
            message: DictionaryService.msg("vOccupation",'TH'),
          },
        ],
      }
    }else if(name === 'gender'){
      return {
        name : 'gender',
        label : DictionaryService.msg("gender",locale),
        type : 'radio',
        disabled: disabled,
        value : value,
        items:[
          {label:DictionaryService.msg("male",locale),value:"0"},
          {label:DictionaryService.msg("female",locale),value:"1"},
        ],
        xs:6,
        requiredUI:false,
        start : <>
              <Grid item  xs={1}><div className="sz-icons"><div className="icon gender"></div></div></Grid>
              <Grid item  xs={4} style={{padding: "0px 8px 0px !important"}}>
              <div style={{    position: 'relative',top: -4,color:"#fff",width: 85}} className={required?"MuiTypography-body1_ rq-lable":"MuiTypography-body1_"}>
                <span style={{fontSize: '12px'}}>{DictionaryService.msg("gender",locale)}</span>
              </div>
              </Grid>
              </> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
          {
            required: required,
            message: DictionaryService.msg("vGender",locale),
          },
        ],
      }
    }else if (name === 'mobileNo'){
      let sendToChannel = this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.sendToChannel ?this.props.buildingInfo.data.sendToChannel : 0;
      return {
        name : 'mobileNo',
        label : DictionaryService.msg("mobileNo",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:6,
        helperText:(sendToChannel === 1?DictionaryService.msg("smotp",locale):""),
        requiredUI:sendToChannel===1?true:required,
        end : <Grid item  xs={1}>

      {/* {
          sendToChannel === 1?
          <Tooltip text={()=> {
            return <>
            <h5 style={{marginBottom: 0}}> <Translate value="passwordInfo1" /> </h5>
              <Translate value="smotp" /> <br/>
            </>
          }} style={{margin: -15,position: 'relative',top: -5}}>
          <IconButton aria-label="delete"  size="small">
                <PriorityHigh style={{fontSize: 15,color:'#fff' }}/>
          </IconButton>
          </Tooltip>:null */}

      {/* } */}


        </Grid>,
        rules: [
          { required: required,
            message: DictionaryService.msg("vMobileNo",locale)
          },
          {
            pattern:/[0][0-9]{9}/,
            message: DictionaryService.msg("errPhoneRegex",locale),
          },
          {
            max:10,
            message: DictionaryService.msg("errMobiledMax",locale),
          },
        ],
      }
    }else if(name === 'areaCode'){
      let sendToChannel = this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.sendToChannel ?this.props.buildingInfo.data.sendToChannel : 0;

      let countryCodes = this.state.countryCodes || []

      {this.state.countryCodes.map(option => (
        <MenuItem key={option.code} value={option.dial_code}   disabled={true}>

        {option.dial_code} ({option.code})

        </MenuItem>
      ))}

      countryCodes = countryCodes.map((option)=>{
        return {value:option.dial_code,label:`${option.dial_code} (${option.code})`}
      })

      return {
        name : 'areaCode',
        label : DictionaryService.msg("areaCode",locale),
        type : 'seletebox',
        disabled: disabled,
        value : value,
        items:countryCodes,
        helperText:(sendToChannel === 1?" ":""),
        xs:4,
        requiredUI:false,
        start :<Grid item  xs={1}><div className="sz-icons"><div className="icon mobile" style={{marginBottom: (sendToChannel === 1?20:0)}}></div></div></Grid>,
        rules: [
          {
            required: required,
            message: DictionaryService.msg("vOccupation",'TH'),
          },
        ],
      }
    }
    else if(name === 'recaptcha'){ //recaptcha
      return {
        name : 'recaptcha',
        value : value,
        type: 'recaptcha',
        rules: [
          {
            required: true,
            message: DictionaryService.msg("recaptcha1",'TH'),
          },
        ],
      }

    }else if(name === 'facebook_like'){ //recaptcha
      return {
        name : 'facebook_like',
        value : value,
        label : DictionaryService.msg("v_facebook_like",locale),
        type: 'facebook_like',
        rules: [
          // {
          //   required: true,
          //   message: DictionaryService.msg("facebook_like",'TH'),
          // },
        ],
      }

    }else {
      return null
    }
  }




  render() {
    const { getFieldDecorator } = this.props.form;
    let emailFlag = this.props.registerUser.email?1:0;
    // console.log("emailFlag",emailFlag)
    let fieldsLogin = this.state.formConfig;
    const { fields } = this.state;
    return <div id="login-"  className="bg_register regis"  >
      {(this.state.loading)?
      <div id="login_" >
          <div style={{ paddingTop: 200,paddingBottom: 200, display: 'table', margin: '0 auto' }}>
              <div className="loader"></div>
          </div>
          </div>
        :null}

<div id="form_login" style={!this.state.loading ? {} : { display: 'none' }} >
    <div className="bt-sz-back fix" onClick={()=>this.props.changePage("login")} >
      <Translate value="back" />
    </div>
    <div className="row regis-fm regis-fm-data ">
    <div className="col-md-12 header-text" >
       <Translate value="registerText" />
    </div>
    {this.state.isCa?<div className="act-a"></div>:<div className="act-b"></div>}


    <div className="act-data">

      {
        this.state.isCa ?
      <>
        <div  className="item-1"><Translate value="regis_h1" /></div>
      </>
      :null
    }

    {
      this.props.registerUser && (this.props.registerUser.socialId === 2 || this.props.registerUser.socialId === 3)?
        <div  className="item-2"><Translate value="regis_h3" /></div>:
        <div  className="item-2"><Translate value="regis_h2" /></div>
    }
    </div>
    {
      fieldsLogin.length > 0?
      <ScValidator
        fbPageRef={this.props.buildingInfo.data.fbPageRef}
        fields={fieldsLogin}
        values={{...fields}}
        facebookAuth={this.props.facebookAuth}
        enableFacebookUserLikes={this.props.enableFacebookUserLikes}
        onReady={(form)=>{
          this.formLogin = form;
        }}
        onSuccess={(data)=>{this.handleSubmit(data)}}
        isDisabled={true}
        setRecaptcha1={(d)=>{
            console.log(d)
            this.setState({recap1: d.recaptcha_text, recap2: d.svg_text})
        }}
        submitFormText={DictionaryService.msg("register",this.props.locale)}
        locale={this.props.locale}
        onChange={this.handleFormChange}
      />
      :null
    }
    {/**
    <FacebookProvider appId="1673614886038454">
      <Like href="https://www.facebook.com/imdb" colorScheme="dark" showFaces onChange={(e)=>{
        console.log(e)
      }} />
    </FacebookProvider>*/}

    </div>
    </div>
    </div>
  }
}

const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});



// const mapDispatchToProps = {}



// const mapStateToProps: any = (state: any) => ({
//   locale: getCurrentLocale(state),
//   config: getCurrentConfig(state)
// });



// export default connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(AppThemes)



const setOTPData = (res)=>{
  return actions.setOTPData(res)
}
const mapDispatchToProps = {
  setOTPData,
}


const RegisterForm:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(Register));
export default RegisterForm;
