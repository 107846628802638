import React ,{Component } from 'react';
import queryString from 'query-string';
import Request  from '../services/request.service';
import {  Route, Switch  } from "react-router-dom";
import { getCurrentLocale, getCurrentConfig } from "../data/utils";
import { setLocale } from 'react-redux-i18n'
import { connect } from 'react-redux'
import AppConfig  from '../services/app.config';
import createLazyContainer from "react-lazy-import";
import Error from "../components/Error";
import Loading from "../components/Loading";
import AES from "crypto-js/aes";
import i18n from "../i18n/index";
import App from '../components/Themes/default/App'
import AnnouncePopup from '../components/AnnouncePopup'
import * as Sentry from '@sentry/browser';
import LoginPage from '../components/Themes/default/LoginPage'
import InfoPage from '../components/Themes/default/InfoPage'
import EditProfilePage from '../components/Themes/default/EditProfilePage'
import ChangePasswordPage from '../components/Themes/default/ChangePasswordPage'
import LoginErrorPage from '../components/Themes/default/LoginErrorPage'
import LoginIfreamPage from '../components/Themes/default/LoginIfreamPage'
import ConfirmOtpPage from '../components/Themes/default/ConfirmOtpPage'
import DictionaryService from "../i18n/DictionaryService";
import ReactGA from 'react-ga';
import Notification  from '../services/notification.service';
import CommonService  from '../services/commonService.service';
import { isAndroid , isIOS , browserName} from 'react-device-detect';
import PopupAgreement from '../components/PopupAgreement'
import { actions } from "../data/otpModel/action";
// import { browserName} from 'react-device-detect';
import moment from 'moment';
import { Modal,Button } from 'antd';
import { async } from 'q';
import { Translate } from 'react-redux-i18n'
import { resolve } from 'styled-jsx/css';
import { thisExpression } from '@babel/types';
import { Input } from 'antd';
import Cookies from 'js-cookie';
import styled from 'styled-components';

declare let chilliController: any;
declare let window: any;
declare let location: any;
declare let FB: any;
const key = 'updatable';

type LoginProps = {
  location: any,
  history: any,
  match: any,
  locale:string,
  setLocaleCustom: (locale: string) => void,
  setOTPData:any
}

type LoginState = {
  img4:any
  announce:any,
  keyBuilding:any,
  pageError:any,
  loginLogin: any,
  loginInfo: any,
  buildingInfo: any,
  user: any,
  config: any,
  confirmloginLoging:boolean,
  ChilliData:any,
  userName:string,
  password:string,
  strUsername : string,
  pappassword : string,
  loginLoginLoading:boolean,
  loginInfoLoading:boolean,
  buildingInfoLoading:boolean,
  count:any,
  loginmode:any,
  social:any,
  detachAds:any,
  loading:any,
  dateStartAds:any,
  freeUser:any,
  socialTypeList:any,
  socialAuth:any,
  reloadAuth:any,
  newBannerId:any,
  reviveBannerId:any,
  timeCount:any,
  notificationError:any,
  modeChilli:any,
  autoLogin:any,
  autoLoginResult:any,
  popupAds:any,
  counterAds:any,
  showPopup:any,
  agreementLoading:any,
  postLoginItem:any,
  specialTheme:any,
  customThemeJson:any
}
const configPass = 'ALPH'

const StyledDiv = styled.div`

    ${props => {
      if (props.active === 'true') {
        return `
        color: #ff1;
        .change-theme-preview{
          color: #ff1;
          .bg-main {
            background: linear-gradient(0deg, ${props.color1} 30%,${props.color2} 90%) !important;
            color: ${props.color2};
          }

          .contact-us {
            background: linear-gradient(0deg, ${props.color1} 30%,${props.color2} 90%) !important;
          }

          .bg_login {
            border: 2px solid ${props.color1} !important;
            background: linear-gradient(0deg, ${props.color1} 30%,${props.color2} 90%) !important;
            z-index: 10;
            &:before{
              background-color: ${props.color1};
              filter: brightness(90%);
            }
          }

          .bg_register{
            border: 2px solid ${props.color1} !important;
            background: linear-gradient(0deg, ${props.color1} 30%,${props.color2} 90%) !important;
            z-index: 10;
            &:before{
              background-color: ${props.color1};
              filter: brightness(90%);
            }
          }

          .MuiInputBase-input.MuiInput-input {
            color: ${props.color2} !important;
          }
          .MuiFormLabel-root.Mui-focused {
            color: ${props.color2} !important;
          }
          .MuiTypography-body1, .MuiTypography-body1_ {
            color: ${props.color2} !important;
          }


          .ant-form-item label {
            color: ${props.color2} !important;
          }

            .ant-btn-link {
              color: ${props.color2} !important;
              filter: brightness(60%);
              &:hover {
                color: ${props.color2} !important;
                filter: brightness(70%);
              }
            }
        }
         `;
      }
      else {
        return `
            color: #000;
        `
      }
    }
  }

`;


class AppThemes extends Component<LoginProps, LoginState> {
  counter:any;
  timer:any;
  fakeAdBanner:any;
  constructor(props) {
    super(props);

    let params: any = queryString.parse(this.props.location.search);
    let socialAuth = false;
    let reloadAuth = false;
    if(params.social){
      socialAuth = true;
    }

    if(params.reload){
      reloadAuth = true;
    }

    this.state = {
      announce:null,
      keyBuilding:'',
      modeChilli:'api',
      timeCount:0,
      pageError:false,
      loginLoginLoading: false,
      autoLogin:false,
      loginLogin: {
        data: {}
      },
      loginInfoLoading: false,
      loginInfo: {
        data: {}
      },
      buildingInfo: {
        data: false
      },
      popupAds:false,
      buildingInfoLoading: false,
      user: false,
      socialTypeList:[],
      userName: "",
      password: "",
      config: false,
      confirmloginLoging: false,
      ChilliData: {},
      strUsername: "",
      pappassword: "",
      count:0,
      loginmode:"default",
      social:[],
      detachAds:false,
      loading:false,
      dateStartAds:false,
      freeUser:false,
      socialAuth:socialAuth,
      reloadAuth:reloadAuth,
      newBannerId:[],
      reviveBannerId:[],
      notificationError:{},
      autoLoginResult:false,
      counterAds:5,
      img4:false,
      showPopup:false,
      agreementLoading:false,
      postLoginItem:{},
      specialTheme:null,
      customThemeJson:null
    };

    this.getBuildingInfo = this.getBuildingInfo.bind(this);
    this.getUserDetail = this.getUserDetail.bind(this);
    this.setLocaleData = this.setLocaleData.bind(this);
    this.OnSubmitLogin = this.OnSubmitLogin.bind(this);
    this.OnSubmitLogOff = this.OnSubmitLogOff.bind(this);
    this.postLoginERegister = this.postLoginERegister.bind(this);
    this.changeLoginmode = this.changeLoginmode.bind(this);
    this.setCountTime = this.setCountTime.bind(this);
    this.updateDetachAds = this.updateDetachAds.bind(this);
    this.updatePageError = this.updatePageError.bind(this);
    this.setPopupAds = this.setPopupAds.bind(this);
    this.setCounterAds = this.setCounterAds.bind(this)
    this.tick = this.tick.bind(this);
    this.setAgreement = this.setAgreement.bind(this);
    // this.checkAdRow = this.checkAdRow.bind(this);
  }

  trackPage = (page) => {
    let pageArr =  page?page.replace("/","").split("-"):[]
    for (const i in pageArr) {
      if( pageArr[i])
       pageArr[i] = pageArr[i].replace(/^./,  pageArr[i][0].toUpperCase())
    }
    let pageName = pageArr.join(" ")
        pageName = pageName ===""?"Login":pageName
    ReactGA.set({page});
    let { buildingInfo } = this.state;
    try{
      let buildingName = buildingInfo.data ? buildingInfo.data.buildingNameEN : "";
      ReactGA.set({ contentGroup3: buildingName ,contentGroup2:pageName,contentGroup1:this.props.locale  });
    }catch (e) {
      console.log("ga",e)
    }
    ReactGA.pageview(page);
  };


  componentDidMount() {
    this.initApp();

  }
  setCounterAds(counterAds){
    this.setState({counterAds:counterAds})
  }

  async getFacebookSDK(){

    let config: any = await AppConfig.getConfig();
    (function(doc, str, id) {
      const fjs:any = doc.getElementsByTagName(str)[0];
      if (doc.getElementById(id)) {return;}
      const js:any = doc.createElement(str);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
      window.fbAsyncInit = function() {
      FB.init({
          appId            : config.fbAppId,
          autoLogAppEvents : true,
          xfbml            : true,
          cookie           : true,
          version          : 'v4.0'
        });
      }

  }


  checkAdRow(){
    var e=document.createElement('div');
    e.id='XEnPmFKLSxGC';
    e.style.display='none';
    document.body.appendChild(e);
    setTimeout(()=>{
      // console.log("zone",this.fakeAdBanner.offsetHeight === 0)
      // let zone = document.getElementsByClassName("aso-zone");
      // let detachAds = false;
      // if(zone && zone.length){
      //   for(let i = 0 ; i<zone.length ;i++){
      //     console.log("zone>",zone[i].hasChildNodes())
      //     if(zone[i] && zone[i].hasChildNodes()){
      //           detachAds = true
      //     }else{
      //
      //     }
      //   }
      // }else{
      //   detachAds = true
      // }
      if(!this.fakeAdBanner || this.fakeAdBanner.offsetHeight === 0){
        // this.setState({detachAds:true,dateStartAds :moment()},()=>{
        //   setTimeout(() => {
        //     this.showMdAdblock();
        //   }, 10);
        // })
      } else {

      }
    },100)
  }

  componentWillReceiveProps(nextProps) {
    const currentPage = this.props.location.pathname;
    const nextPage = nextProps.location.pathname;
    if (currentPage !== nextPage) {
      this.trackPage(nextPage);
    }
  }


  setCountTime(data){
    console.log("setCountTime",data)
    // this.checkAdRow();
    // this.setState({dateStartAds :data})
  }

  showMdAdblock(){
    // console.log("showMdAdblock",this.state.newBannerId.length  ,  this.state.reviveBannerId)
    //&&   window.location.pathname != '/user-info-iframe'
    if(this.state.detachAds && (this.state.newBannerId.length > 0 ||  this.state.reviveBannerId.length > 0 )  ){ //
      let msg =  "Please disable adblock to show page, then refresh the page";
      if(this.props.locale === 'TH'){
        msg = "กรุณาปิดแอพที่ปิดกั้นโฆษณา เพื่อแสดงหน้านี้ จากนั้นรีเฟรชหน้าจอ"
      }
      Notification.error({
         message:"",
         description: msg,
           username:this.state.strUsername
       });
    }

  }

  setStrUsername(item){
    Request.post("/login/rememberLoginStr", item, {
      "x-access-id":
      this.state.buildingInfo.data.accessToken
    }).then((res: any) => {
      // console.log("setUserrememberLogin",res);
    })
  }

  awaitWatchAds(){
    return new Promise((resolve)=>{
      let time:any = CommonService.calRemainingTime(this.state.dateStartAds);
      if( (this.state.newBannerId.length > 0 ||  this.state.reviveBannerId.length > 0 ) || this.state.socialAuth || this.state.reloadAuth){
        time = 0;
      }
      setTimeout(() => {
          resolve()
      }, time);
      let timeCount:any = time?parseInt(time)/1000 :0
      this.setState({
        timeCount: parseInt(timeCount)
      },()=>{
        this.timer = setInterval(this.tick, 1000);
      })
    })
  }

  tick(){
    this.setState({
      timeCount:  parseInt(this.state.timeCount) - 1
    },()=>{
      if(this.state.timeCount < 0){
        clearInterval(this.timer);
      }
    })
  }

  updateDetachAds(v){
    setTimeout(() => {
      this.showMdAdblock();
    }, 10);
  }

  async initApp() {
    let config: any = await AppConfig.getConfig();
    let params: any = queryString.parse(this.props.location.search);
    if (!params.nasid ) {
      CommonService.redirectChilli();
      return;
    }
    // Notification.error({
    //    message:"",
    //    description: 'initApp',
    //  });
    CommonService.getChilli(config,params).then((data: any) => {

      if(!data){
        console.log(params);
        Sentry.captureMessage('/getChilli'+moment());
      }
      Request.post("/register/stamplogchilli", {
        cmd:'getchilli',
        status:data?'sucess':'error',
        data:data
      })
      this.setState({ ChilliData: data });
      if (!data) {
          let { buildingInfo } = this.state;
          buildingInfo.loading = true;
          buildingInfo.resultCode = "50000";
          let msg = (i18n[this.props.locale]?i18n[this.props.locale].chilliError:"");
          buildingInfo.errorMessage = msg;
          this.setState({ buildingInfo: buildingInfo,buildingInfoLoading:true });
          this.trackPage("/error")
          Notification.error({
            message:"",
            description: msg,
            // username:this.state.strUsername,
            onCancel:()=>{
              CommonService.redirectChilli();
            }
          });
        return ;
      }
      let $lang = this.props.locale
      console.log("modeChilli",data)
      if(data.modeChilli === 'redirect'){
        let replyMsg = params.reply;
        if(params.res === 'succeed'){
          Notification.error({
              message:"",
              description: params.reply,
              // username:this.state.strUsername
          });
        }else if(params.res === 'failed'){
          let code:any = null;
          let errormessage = replyMsg;
          if (replyMsg == "Your maximum never usage time has been reached") {
              if ($lang == 'TH') {
                  errormessage = 'เวลาการใช้งานของท่านหมดแล้ว';
              } else {
                  errormessage = 'Your maximum usage time has been reached';
              }
              code = "C40103";

        }else if (replyMsg && replyMsg.indexOf('You are already logged in') > -1) {
          if ($lang == 'TH') {
              errormessage = 'ท่านไม่สามารถ login ได้เนื่องจาก login เครื่องอื่นอยู่';
          } else {
              errormessage = "You are already logged in on another device";
          }
          code = "C40111";
          this.setState({pageError:errormessage,notificationError:{
            message:errormessage,
            description:'',
            resultCode:code
          }})
          this.props.history.push({ pathname:"/login-error", search:this.props.location.search})

        } else {

            if ($lang == 'TH') {
                errormessage = 'ล็อกอินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง';
            } else {
                errormessage = 'Unsuccessful login Please try again';
            }
            code = "C50010"
        }

          Notification.error({
              message:"",
              description: errormessage,
              resultCode:code,
              // username:this.state.strUsername
          });

        }else if(params.res === 'already'){
          console.log("already")

        }
        // try{
        //   Cookies.remove('logon', { path: '' });
        // }catch(e){
        //
        // }

      }


      if(data.modeChilli){
        this.setState({
          modeChilli:data.modeChilli
        })
      }
      let reqItem = {
        challenge: data.challenge,
        nasid: data.nasid || params.nasid,
        mac: data.redir ? data.redir.macAddress : params.mac,
        clientState:data.clientState
      };

      console.log("config",config);

      this.getBuildingInfo(reqItem).then(async (res: any) => {
        if (res.resultCode && res.resultCode[0] === "2") {

          // console.log("getBuildingInfo res",res);


            // this.getFacebookSDK();

            const page = this.props.location.pathname;

            // if(data.clientState === 0){
            //   if(this.state.buildingInfo.data.enableIframeLoginSuccess && this.state.buildingInfo.data.iframeLoginSuccessUrl){
            //     this.loginpagelog();
            //   }
            // }
          // if(config.name === 'local'){
          //   await new Promise((resolve)=>{
          //     setTimeout(()=>{
          //       resolve(true)
          //     },3500)
          //   })
          // }

            this.trackPage(page);
          if (data.clientState === 1 ) {
            this.getUserDetail();
          } else {
            console.log("this.state.autoLoginResult",this.state.autoLoginResult);

              if(this.state.autoLoginResult){
                Notification.error({
                  message:"",
                  description: this.state.autoLoginResult.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
                  resultCode:this.state.autoLoginResult.resultCode,
                  // username: this.state.strUsername
                });
              }

              // let params: any = queryString.parse(this.props.location.search);
              // if (params.preview) {

            if(this.state.autoLogin && !params.preview){
              console.log("AUTOLOGIN",this.state.autoLogin);
              let data:any = await this.logonChilli(this.state.autoLogin.username,this.state.autoLogin.password,{},'login');
              console.log("AUTOLOGIN data",data);
              if (data.clientState === 1 ) {
                this.getUserDetail();
              }else{

                if(data.clientState === 0){
                  if(this.state.buildingInfo.data.enableIframeLoginSuccess && this.state.buildingInfo.data.iframeLoginSuccessUrl){
                    this.loginpagelog();
                  }
                }

                /*******************************/
                console.log(this.state.autoLogin);
                // this.setState({
                //   strUsername: this.state.autoLogin.username,
                //   pappassword: this.state.autoLogin.password
                // });

                let errormessage = data.message || '';
                let replyMsg = errormessage;
                let code = '';
                  console.log("errormessage",replyMsg);
                if (replyMsg == "Your maximum never usage time has been reached") {
                    if ($lang == 'TH') {
                        errormessage = 'เวลาการใช้งานของท่านหมดแล้ว';
                    } else {
                        errormessage = 'Your maximum usage time has been reached';
                    }
                    code = "C40103";
                    Notification.error({
                        message:"",
                        description: errormessage,
                        resultCode:code,
                        // username:this.state.strUsername
                    });

              }else if (replyMsg && replyMsg.indexOf('You are already logged in') > -1) {
                if ($lang == 'TH') {
                    errormessage = 'ท่านไม่สามารถ login ได้เนื่องจาก login เครื่องอื่นอยู่';
                } else {
                    errormessage = "You are already logged in on another device";
                }
                code = "C40111";


                this.setState({
                      pageError:"already",//["You are already logged in - access denied"].indexOf(errormessage) > -1?"already":false,
                      notificationError:{
                      message:errormessage,
                      description:'',
                      resultCode:code
                }})
                this.props.history.push({ pathname:"/login-error", search:this.props.location.search})

              } else {

                  if ($lang == 'TH') {
                      errormessage = 'ล็อกอินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง';
                  } else {
                      errormessage = 'Unsuccessful login Please try again';
                  }
                  code = "C50010"
                  Notification.error({
                      message:"",
                      description: errormessage,
                      resultCode:code,
                      // username:this.state.strUsername
                  });
              }
                /*******************************/
                let { loginInfo } = this.state;
                loginInfo.loading = true;
                this.setState({ loginInfo: loginInfo,loginInfoLoading:true });
                this.locationGo(this.state)
              }
            }else{

              if(data.clientState === 0){
                if(this.state.buildingInfo.data.enableIframeLoginSuccess && this.state.buildingInfo.data.iframeLoginSuccessUrl){
                  this.loginpagelog();
                }
              }

              let { loginInfo } = this.state;
              loginInfo.loading = true;
              this.setState({ loginInfo: loginInfo,loginInfoLoading:true });
              this.locationGo(this.state)
            }

          }
        } else {
          this.trackPage("/error")
        }
      });
    });
  }


  loginpagelog(){
    let chilliData = this.state.ChilliData
    let params: any = queryString.parse(this.props.location.search);
    Request.post("/loginpagelog", {
    	"custGroupId":this.state.buildingInfo.data.custgroupId,
    	"usermac":chilliData.redir ? chilliData.redir.macAddress : params.mac,
    	"apmac":this.state.buildingInfo.data.apmac,
    	"type":"login_landing",
      "loginAlphaboxFlag":1
    })
  }





   logonChilli(username, password,resData,typeLogin) {
    return new Promise(async resolve => {
      await this.awaitWatchAds()
      let config: any = await AppConfig.getConfig();
      let params: any = queryString.parse(this.props.location.search);
      let mode = this.state.modeChilli;
      // mode  = 'xxx'
      if(mode !== 'api'){
        console.log("resData")
        if(resData.resultCode ==="20001"){
          let msg = (i18n[this.props.locale]?i18n[this.props.locale].emailNotConfirmed:"")  + resData.errorMessage;
          if(typeLogin === 'register'){
            msg = (i18n[this.props.locale]?i18n[this.props.locale].successfullyRegisteredAndLogged:"")  + resData.errorMessage;
          }
          // (i18n[this.props.locale]?i18n[this.props.locale].emailNotConfirmed:"")  + resData.errorMessage;
          Notification.successConfirm({
              message:"",
              description: msg,
              onOk:(close)=>{
                CommonService.logonChilli(username, password, config, params,this.state.modeChilli).then((data)=>{
                  Request.post("/register/stamplogchilli", {
                    cmd:'logonchilli',
                    status:data?'sucess':'error',
                    data:data
                  })
                  resolve(data);
                })
                close()
              }
            });
        }else{

          CommonService.logonChilli(username, password, config, params,this.state.modeChilli).then((data)=>{
            Request.post("/register/stamplogchilli", {
              cmd:'logonchilli',
              status:data?'sucess':'error',
              data:data
            })
            resolve(data);
          })

        }

      }else{
        console.log("logonChilli>>>>>>>>",username, password);

        CommonService.logonChilli(username, password, config, params,this.state.modeChilli).then((data)=>{
          Request.post("/register/stamplogchilli", {
            cmd:'logonchilli',
            status:data?'sucess':'error',
            data:data
          })
          resolve(data);
        })
      }


    });
  }


  async getBuildingInfo(reqItem) {
    let config: any = await AppConfig.getConfig();
    console.log(reqItem);
    return Request.post2("/login/locationInfo", reqItem).then(async (res: any) => {
      console.log(res);
        if (res.resultCode && res.resultCode[0] === "2") {
        let { buildingInfo } = this.state;
          buildingInfo.loading = true;
          buildingInfo.resultDescription = res.resultDescription;
          buildingInfo.errorMessage = res.errorMessage;
          buildingInfo.data = res.resultData && res.resultData.length > 0 ? res.resultData[0]: {};
          let loginmode = 'default'

          buildingInfo.data.mac = reqItem.mac

          if(config.name === 'local'){

            // buildingInfo.data.reviveBannerId = "4171,4171,0,https://szlogin-api.smartzoneonline.com/api/img/covid.jpg"
            // buildingInfo.data.logoHeader = "https://smartrms.s3.ap-southeast-1.amazonaws.com/758bf5e0-09ec-11ea-9841-4da60af07ce1kups.png";
            // buildingInfo.data.logoForm = "https://smartrms.s3.ap-southeast-1.amazonaws.com/758bf5e0-09ec-11ea-9841-4da60af07ce1kups.png";
            // buildingInfo.data.loginAlphaboxFlag = 1;
            // buildingInfo.data.newBannerId = "";
            // buildingInfo.data.enableAdvertisement = 0;
            // buildingInfo.data.newBannerId = "0,0,0,http://127.0.0.1:8855/api/donedeal-image"
            // buildingInfo.data.advertisement = {
            //         "w": {
            //             "adsId": 13,
            //             "adsName": "rms",
            //            "url": "https://smartrms.s3.ap-southeast-1.amazonaws.com/27b79a10-22f4-11ea-8a55-9f30159a2469200_3.jpg",
            //            "redirectUrl": "https://smartrms.s3.ap-southeast-1.amazonaws.com/27b79a10-22f4-11ea-8a55-9f30159a2469200_3.jpg"
            //            },
            //         "x": {
            //             "adsId": 13,
            //             "adsName": "rms",
            //            "url": "https://smartrms.s3.ap-southeast-1.amazonaws.com/27b79a10-22f4-11ea-8a55-9f30159a2469200_3.jpg",
            //            "redirectUrl": "https://smartrms.s3.ap-southeast-1.amazonaws.com/27b79a10-22f4-11ea-8a55-9f30159a2469200_3.jpg"
            //            },
            //         "y": {
            //             "adsId": 13,
            //             "adsName": "rms",
            //            "url": "https://smartrms.s3.ap-southeast-1.amazonaws.com/27b79a10-22f4-11ea-8a55-9f30159a2469200_3.jpg",
            //            "redirectUrl": "https://smartrms.s3.ap-southeast-1.amazonaws.com/27b79a10-22f4-11ea-8a55-9f30159a2469200_3.jpg"
            //            },
            //         "z": {
            //             "adsId": 13,
            //             "adsName": "rms",
            //            "url": "https://smartrms.s3.ap-southeast-1.amazonaws.com/27b79a10-22f4-11ea-8a55-9f30159a2469200_3.jpg",
            //            "redirectUrl": "https://smartrms.s3.ap-southeast-1.amazonaws.com/27b79a10-22f4-11ea-8a55-9f30159a2469200_3.jpg"
            //            }
                  // }


            // buildingInfo.data.displayLoginForm = 2;
            // buildingInfo.data.announce = "announce!!!!";
            // buildingInfo.data.announceExpire = moment().add("days",1)

            // buildingInfo.data.reviveBannerId = "2599,2599,2599,2599"//"45680,45690,47029,47030"
            // buildingInfo.data.newBannerId =  "0,0,47029,47030"
            // buildingInfo.data.reviveBannerId = "0,0,2599"//"45680,45690,47029,47030"
            // buildingInfo.data.newBannerId = "45677,45687"
            // buildingInfo.data.socialContact =  " Line : @kupscafe" //Tel: 06-2723-4556 <br>
            // buildingInfo.data.isMobileLogin = 1;
           }

           if(window.preview){
             buildingInfo.data = {...buildingInfo.data,...window.preview}
           }
           console.log("window.preview ",window.preview )



           let customThemeJson:any = null;
           try {
             if(buildingInfo.data.customThemeJson){
               customThemeJson = JSON.parse(buildingInfo.data.customThemeJson)
             }
           } catch (error) {
            console.log(error);

           }

           let specialTheme:any = null;
           try {
             if(buildingInfo.data.custgroupId === 1489){
               specialTheme = 1
             }
           } catch (error) {
            console.log(error);

           }

            // buildingInfo.data.enableIframeLoginSuccess = buildingInfo.data.enableIframeLoginSuccess === "1"?true:false;
            buildingInfo.data.enableIframeLoginSuccess = false;
           if(buildingInfo.data.redirectURL){
               buildingInfo.data.iframeLoginSuccessUrl =  buildingInfo.data.redirectURL
               buildingInfo.data.enableIframeLoginSuccess = true;
           }


          if(!buildingInfo.data.displayLoginForm ){
              buildingInfo.data.displayLoginForm = 1
          }

          let announce = ''
          if(buildingInfo.data.announce && (!buildingInfo.data.announceExpire || moment().isBefore(buildingInfo.data.announceExpire)) ){
              announce = buildingInfo.data.announce;
              announce = announce?announce.trim():'';
          }

          if(buildingInfo.data &&(buildingInfo.data.displayLoginForm === 2 || buildingInfo.data.displayLoginForm ===3)){
              loginmode = 'social'
          }

          let img4:any = false
            try {

              let urlImage:any = null;
              // let type:any = null
              let newBannerId_ = buildingInfo && buildingInfo.data && buildingInfo.data.newBannerId? buildingInfo.data.newBannerId:"";
              let newBannerId = newBannerId_.split(",") || [];

              let reviveBannerId_ = buildingInfo && buildingInfo.data && buildingInfo.data.reviveBannerId?buildingInfo.data.reviveBannerId:"";
              let reviveBannerId = reviveBannerId_.split(",") || [];

                if(newBannerId.length > 3 &&newBannerId[3] !== '0'){
                  if(this.validURL(newBannerId[3])){
                    urlImage = newBannerId[3];
                    // type = 'url';
                  }
                  // newBannerIdArr.push({id:props.newBannerId[3]})
                }

                if(!urlImage && reviveBannerId.length > 3 && reviveBannerId[3] !== '0'){
                  if(this.validURL(reviveBannerId[3])){
                    urlImage = reviveBannerId[3];
                    // type = 'url';
                  }
                }

                if(urlImage){
                        console.log("urlImage",urlImage);
                let res4 = await this.getURLImage(urlImage);

                // .then(r=>{
                  if(res4.resultCode === "20000"){
                    console.log(res4.resultData);
                    img4 = res4.resultData
                    // this.setState({img4:res4.resultData})
                  }
                //   console.log("loginpagelog",r);
                // })
                    // console.log("res4",res4);


                }


            } catch (error) {
              console.log("error",error);

            }




          buildingInfo.enableFeatureLoginTemplate = buildingInfo.data ? buildingInfo.data.enableFeatureLoginTemplate : 0;
          buildingInfo.templateName = buildingInfo.data ? buildingInfo.data.templateName : "";
          buildingInfo.resultCode = res.resultCode;
          try{
            if(  buildingInfo.data.logoForm ){
              var styleElem = document.head.appendChild(document.createElement("style"));

                styleElem.innerHTML = `
                .bg_login:before {
                  background-image: url(${buildingInfo.data.logoForm}) !important;
                  background-size: 45% auto !important;
                }
                .bg_register:before {
                  background-image: url(${buildingInfo.data.logoForm}) !important;
                  background-size: 45% auto !important;
                }
                `;
            }
          }catch (e) {
            console.log(e);
          }
          let social:any =[]
          let newBannerId  = buildingInfo.data  && buildingInfo.data.newBannerId  ? buildingInfo.data.newBannerId.split(",") : []
          let reviveBannerId  = buildingInfo.data  && buildingInfo.data.reviveBannerId  ? buildingInfo.data.reviveBannerId.split(",") : []
          let _social  = buildingInfo.data.socials || []
          try{
            social = _social.map(r=>r.socialId)
            if(buildingInfo.data.isMobileLogin === 1){
              social.push(0)
            }
          }catch (e) {
            console.log(e);
          }

          try {
            // console.log("social",social);
            if(social.indexOf(2) > - 1){
              this.getFacebookSDK();
            }
          } catch (error) {
            console.log(error);

          }



          let autoLogin = false
          if(buildingInfo.data.autoLogin){
            autoLogin = buildingInfo.data.autoLogin;
          }
          let autoLoginResult = false
          if(buildingInfo.data.autoLoginResult){
            autoLoginResult = buildingInfo.data.autoLoginResult;
          }
          console.log("autoLogin",autoLogin,autoLoginResult);
          this.setState({ customThemeJson:customThemeJson,specialTheme:specialTheme,  img4:img4,autoLogin:autoLogin,autoLoginResult:autoLoginResult,loginmode:loginmode,buildingInfo: buildingInfo,buildingInfoLoading:true,social:social ,announce:announce,newBannerId:newBannerId,reviveBannerId:reviveBannerId});
          try{
            if(buildingInfo.data.usr && buildingInfo.data.pwd){
              this.setState({freeUser:{
                    username: buildingInfo.data.usr,
                    password: buildingInfo.data.pwd,
                  }})
                  console.log("freeUser",buildingInfo.data.usr && buildingInfo.data.pwd);

            }
          }catch (e) {
            console.log(e);
          }
          if (buildingInfo.data.defaultLang) {
            try{
              var haslocalStorage = !!localStorage;
              if (haslocalStorage) {
                  try {
                    if (!localStorage.getItem("locale")) {
                      let languageCode = buildingInfo.data.defaultLang.toUpperCase();
                      if (["EN", "TH"].indexOf(languageCode) > -1) {
                        this.setLocaleData(languageCode);
                      }
                    }
                  }
                  catch (e) {
                    this.setLocaleData(buildingInfo.data.defaultLang.toUpperCase());
                }
              }
            }catch (e) {
              this.setLocaleData(buildingInfo.data.defaultLang.toUpperCase());
            }
          }
          try {
            if(buildingInfo.data.BackgrounUrl){
              document.getElementsByTagName("body")[0].style['background-image'] = `url(${buildingInfo.data.BackgrounUrl})`;
            }
          } catch (error) {
            console.log(error);
          }

        } else {
          let userAgent = navigator.userAgent || "";

          if(res.resultCode === "50011"){
            Notification.confirm({
            message:"",
            resultCode:res.resultCode,
            okText:this.props.locale==='EN'?'OK':'ตกลง',
            cancelText:this.props.locale==='EN'?'Close':'ปิด',
            description: <div>
              {res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")}
                <div  style={{marginTop:8,fontSize: 14,}}>
                  <Translate value="redirectRegisAlphabox"/>
                </div>
                <div  style={{marginTop:8}}>
                  <Input id="g-alphabox"  />
                </div>
                <div  style={{marginTop:8,fontSize: 13,color:'#E85050'}}>
                  <Translate value="redirectRegisAlphabox2"/>
                </div>

            </div>,
            onOk:(close)=>{
              let e:any =  document.getElementById('g-alphabox')
              let value = e?e.value:null;
              if( value === configPass){
                window.location.href = 'http://192.168.182.1:4300'
              }
              console.log("alphabox",value,configPass)
            }
            });
          }else{
            console.log(res);
            if(res.resultCode === 'FE'){
              // Notification.error({
              //     message:"",
              //     resultCode:res.resultCode,
              //     description: res.errorMessage + " : " + i18n[this.props.locale].none_building
              //     // + " <br> "+userAgent,
              // });
              console.log({
                  message:"",
                  resultCode:res.resultCode,
                  description: res.errorMessage + " : " + i18n[this.props.locale].none_building,
                  userAgent:userAgent,
              });

              // window.location = config.smartTVUrl +  window.location.search
              //none_building
            }else{
              Notification.error({
                  message:"",
                  resultCode:res.resultCode,
                  description: res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
                  // username:this.state.strUsername
              });
            }

            Sentry.captureMessage('/login/locationInfo|'+moment());
          }

          let { buildingInfo } = this.state;
          buildingInfo.loading = true;
          buildingInfo.resultCode = res.resultCode;
          buildingInfo.resultDescription = res.resultDescription;
          if(res.resultCode === 'FE'){
              buildingInfo.errorMessage = "ระบบกำลังส่งท่านไปยังหน้าจอเข้าสู่ระบบ ถ้าหน้าจอนี้โหลดนาน กรุณา <a href='"+config.smartTVUrl +  window.location.search+"'> คลิกที่นี่ </a>"
              window.location = config.smartTVUrl +  window.location.search;
            }else{
              buildingInfo.errorMessage = res.errorMessage  + " : " + i18n[this.props.locale].none_building + " : "+userAgent;
          }
          this.setState({ buildingInfo: buildingInfo,buildingInfoLoading:true });
          //alphabox
          this.trackPage("/error")

        }
        // let qadasd = 1
        // qadasd.asdas =12
        this.checkAdRow();
        return res;
      })
      .catch(e => {
        this.trackPage("/error")
        let { buildingInfo } = this.state;
        buildingInfo.loading = true;
        buildingInfo.resultCode = "50000";
        let errorMessage = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        buildingInfo.resultDescription = errorMessage;
        buildingInfo.errorMessage = errorMessage;
        this.setState({ buildingInfo: buildingInfo,buildingInfoLoading:true });
        Notification.error({
            message:"",
            description:errorMessage ,
            // username:this.state.strUsername
        });
        console.log(e);

        Sentry.captureMessage('/login/locationInfo|'+moment());
        console.log("catch",e);
        window.location = config.smartTVUrl +  window.location.search
        return e;
      });
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  getURLImage(url){
    console.log("getOther",url);
    return Request.getOther(url, {})
  }

  async getUserDetail() {
    this.setState({loginInfoLoading:false });
        let config: any = await AppConfig.getConfig();
    console.log("getUserDetail")
    return new Promise(async resolve => {
    let item:any = {
      sessionId: this.state.ChilliData.session ? this.state.ChilliData.session.sessionId :"",
      custGroupId: this.state.buildingInfo.data.custgroupId,
      strUsername: this.state.strUsername,
      pappassword: this.state.pappassword
    };

    if (!item.sessionId) {
      if (this.props.locale === "EN") {
        Notification.error({
          message:"",
          description: "Connection error",
          // username:this.state.strUsername
        });
      } else {
        Notification.error({
          message:"",
          description: "ไม่สามารถเชื่อต่อระบบได้ กรุณาติดต่อ Callcenter",
          // username:this.state.strUsername
        });
      }
      this.setState({ confirmloginLoging: false });
      return false;
    }
    let params: any = queryString.parse(this.props.location.search);

    console.log(item);
    Request.get("/login/userdetail", item, {
      "x-access-id": this.state.buildingInfo.data.accessToken
    }).then((res: any) => {
      console.log(res);
        this.setState({ confirmloginLoging: false });

        // if(params.preview){
        //   alert(`${isAndroid?'Is Android':'Not Android'} , ${isIOS?'Is IOS':'Not IOS'} , ${browserName}`);
        //
        //   setTimeout(()=>{
        // if(isAndroid){
        //     window.location.href = 'fb://page/301772873303705';
        // }else if(isIOS){
        //     window.location.href = 'fb://profile/301772873303705';
        // }else{
        //     window.location.href = 'https://www.facebook.com/ChokdeePlace';
        // }
        //   },0)
        //
        //   return
        // }



        if (res.resultCode && res.resultCode[0] === "2") {


          let { loginInfo } = this.state;
          loginInfo.loading = true;
          loginInfo.data = res.resultData ? res.resultData : {};
          loginInfo.resultCode = res.resultCode;
          let socialTypeList = loginInfo.data.socialTypeList || []
          // console.log("/user-info-iframe/1",res.resultData , (res.resultData.isShowIFrame) , this.state.buildingInfo.data.enableIframeLoginSuccess);
          if(res.resultData && (res.resultData.isShowIFrame) && this.state.buildingInfo.data.enableIframeLoginSuccess){
            // this.props.history.push({ pathname:"/user-info-iframe", search:this.props.location.search})
            setTimeout(()=>{
                // console.log("/user-info-iframe/2");
                this.props.history.push({ pathname:"/user-info-iframe", search:this.props.location.search})
            },0)
          }

          // if(res.resultData && ){
          //   setTimeout(()=>{
          //       this.props.history.push({ pathname:"/user-info-iframe", search:this.props.location.search})
          //   },0)
          // }


          try {
            if(res.resultData && this.state.buildingInfo.data.redirectURL && ['http://www.ucimedia.co.th'].indexOf(this.state.buildingInfo.data.redirectURL) > -1){
               setTimeout(()=>{
                  window.location.href = this.state.buildingInfo.data.redirectURL;
               },0)
            }

            if(this.state.buildingInfo.data.custgroupId === 1514){
               setTimeout(()=>{
                 if(isAndroid){
                     window.location.href = 'fb://page/301772873303705';
                 }else if(isIOS){
                     window.location.href = 'fb://profile/301772873303705';
                 }else{
                     window.location.href = 'https://www.facebook.com/ChokdeePlace';
                 }
               },0)
            }




             // 1514
          } catch (error) {
            console.log(error);
          }

          setTimeout(()=>{
            this.setState({ user: res.resultData,loginInfo: loginInfo,loginInfoLoading:true,socialTypeList },()=>{});
          },10)



        } else {
          Notification.error({
            message:"",
            description: res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
            resultCode:res.resultCode,
            // username:this.state.strUsername
          });
          this.setState({loginInfoLoading:true });
          // this.OnSubmitLogOff()
          this.props.history.push({ pathname:"/", search: this.props.location.search});
        }
        resolve({req:item,res:res})
      })
      .catch(e => {
        console.log(e);
        this.setState({ confirmloginLoging: false,loginInfoLoading:true  });
        Notification.error({
          message:"",
          description:e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
          // username:this.state.strUsername
        });
        Sentry.captureMessage('/login/userdetail|'+moment());

        resolve({req:item,res:e})

      });
    });
  }

  setLocaleData(languageCode) {
    this.props.setLocaleCustom(languageCode);
  }
  changeLoginmode(loginmode){
    this.setState({loginmode:loginmode})
  }

  async OnSubmitLogOff() {
    console.log("OnSubmitLogOff");

    await this.ChilliLogOff();
    this.setState({ user: false, });
    this.props.history.push({ pathname:"/", search: this.props.location.search});
  }

  async ChilliLogOff() {
    return new Promise(async resolve => {
      let config: any = await AppConfig.getConfig();
      let params: any = queryString.parse(this.props.location.search);
      if (params.preview) {
        if (this.state.userName) {
          return resolve(CommonService.mockupChilliS1());
        }
        return resolve(CommonService.mockupChilliS0());
      }


      // if(this.state.modeChilli !== 'api'){
        window.location.href = 'http://192.168.182.1:3990/logoff'
        return ;
      // }


      let chilli_https =  params.c === "1" || params.c === 1 ? true :false;
      if (location.protocol != 'https:'){
        chilli_https = false;
      }
      if(!chilli_https){
        chilliController.host = config.chilli_host;
        chilliController.port = config.chilli_port;
      }else{
        chilliController.host = config.chilli_hosts;
      }
      chilliController.interval = 60;
      chilliController.ssl = chilli_https;
      chilliController.onError = err => {
        this.ErrorChilli("Logout",{
          nasid:params.nasid,
          mac:params.mac,
          called:params.called,
          uamip:params.uamip,
        })
        return resolve(false);
      };
      chilliController.onUpdate = res => {
        let data = chilliController;
        return resolve(data);
      };
      chilliController.logoff();
    });
  }

  updatePageError(pageError){
    this.setState({pageError:pageError})
  }

  ErrorChilli(cmd,obj ={}){
    let error = {
      ...obj,
      "hostname":window.location.hostname,
      "type-error":cmd
    }
    console.log("error",JSON.stringify(error))

    setTimeout(() => {
      // throw 'ErrorChilli [' + obj['nasid'] + "] ["+window.location.hostname+"]";
        Sentry.captureMessage('ErrorChilliAPI [' + obj['nasid'] + "] ["+window.location.hostname+"]");
    }, 3000);


  }

  setUsername(item){
    Request.post("/login/rememberLogin", item, {
      "x-access-id": this.state.buildingInfo.data.accessToken
    }).then((res: any) => {
      console.log("setUserrememberLogin",res);
    })
  }



  async OnSubmitLogin(user) {
    if (this.state.confirmloginLoging) {
      return;
    }
    // if (user.condition == false) {
    //   if (this.props.locale === "EN") {
    //    Notification.error({
    //       message:"",
    //       description:`You must accept the usage agreement by clicking on the checkbox below the password form to login.`,
    //         // username:this.state.strUsername
    //     });
    //   } else {
    //     Notification.error({
    //       message:"",
    //       description:"ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้",
    //       // username:this.state.strUsername
    //     });
    //   }
    //   return false;
    // }

      this.setState({ confirmloginLoging: true });
      let config: any = await AppConfig.getConfig();
      let params: any = queryString.parse(this.props.location.search);
      console.log("mode:",this.state.modeChilli)
      let Chilli: any = await CommonService.getChilli(config,params,this.state.modeChilli);
      Request.post("/register/stamplogchilli", {
        cmd:'getchilli',
        status:Chilli?'sucess':'error',
        data:Chilli
      })
      Request.post("/register/stamplogchilli", {
        cmd:'getchilli',
        status:Chilli?'sucess':'error',
        data:Chilli
      })


      // console.log("Chilli",Chilli,Chilli.challenge);
      if (!Chilli) {
          Notification.error({
            message:"",
            description: (i18n[this.props.locale]?i18n[this.props.locale].chilliError:""),
            // username:this.state.strUsername
          });
          this.setState({ confirmloginLoging: false });
        return ;
      }

      this.setState({
        userName: user.username,
        password: user.password,
        ChilliData: Chilli
      });



      // <<<<<<<<
      try {
        this.setUsername({
           "mac": this.state.buildingInfo.data.mac,
           "username": user.username,
           "password": user.password?AES.encrypt(user.password, "szlogin").toString():'',
           "type": 0,
           "remember":user.remember
        })
      }catch (e) {
        console.log(e);
      }
      // console.log("setUserrememberLogin",user.password?AES.encrypt(user.password, "szlogin").toString():'');

      // try {
      //     if (user.remember) {
      //       localStorage.setItem("user",AES.encrypt(JSON.stringify(user), "szlogin"));
      //   } else {
      //       localStorage.setItem("user",AES.encrypt(JSON.stringify({remember:false}), "szlogin"));
      //   }
      // }catch (e) {
      //   console.log(e);
      // }

      this.setState({ confirmloginLoging: true });
      let d = await this.postLogins({flag:"login",...user})
      console.log("dddd",d)

        let logindata:any = {}
        if(d.logindata && d.logindata.res && d.logindata.res.resultData){
          logindata = d.logindata.res.resultData
        }

        // if(){
        //     this.loginpagelog();
        // }

        let label = (d.resultCode && d.resultCode[0] ==="2"?"Successfully":"Error") +" : "+d.resultCode+" $ "+( d.resultCode && d.resultCode[0] ==="2" ?"Success": ((d.msg ? d.msg :d.msgE?d.msgE:d.pageError)))
        ReactGA.event({
          category: 'Login Page',
          action: logindata.ueerType?logindata.ueerType:"",
          label: label
        });
        console.log("ReactGA.event",{
          category: 'Login Page',
          action: logindata.ueerType?logindata.ueerType:"",
          label: label
        },d)

        if(d && d.pageError){
          this.setState({pageError:d.pageError,notificationError:{
            message:"",
            description:d.msgE,
            resultCode:d.resultCode
          }})
          this.props.history.push({ pathname:"/login-error", search:this.props.location.search})
        }

        if(d && d.msgE && !d.pageError){
          Notification.error({
            message:"",
            description:d.msgE,
            resultCode:d.resultCode,
            // username:this.state.strUsername

          });
        }


       if(this.state.buildingInfo.data.enableIframeLoginSuccess && this.state.buildingInfo.data.iframeLoginSuccessUrl){

        let resultData_logindata:any = {};
        try{
          resultData_logindata = d.logindata.res.resultData
        }catch(e){

        }

          // if(resultData_logindata && resultData_logindata.userType && resultData_logindata.userType != "VIP"){
          //   let { user } = this.state;
          //   if(user){
          //     user.isShowIFrame = true
          //     this.setState({
          //       user:user
          //     })
          //     this.props.history.push({ pathname:"/user-info-iframe", search:this.props.location.search})
          //   }
          // }
        }

      this.setState({ confirmloginLoging: false });
  }

  async postLogins(user){
    if((this.state.buildingInfo.data && this.state.buildingInfo.data.enableAdvertisement === 0 )&& this.state.detachAds && (this.state.newBannerId.length > 0 ||  this.state.reviveBannerId.length > 0 )){
      this.showMdAdblock();
      return {logindata:{}}
    }
    this.setState({ loading: true });
    let resLast;
    let retry = 3;
    let msg = '';
    let resList:any = []
    // console.log("this.state.buildingInfo.data.custgroupId",this.state.buildingInfo.data)
    for(let i  = 1;i<=retry;i++){
      let item:any = {
        userName: user.username,
        password: user.password,
        custGroupId: this.state.buildingInfo.data.custgroupId,
        challenge: this.state.ChilliData.challenge
      };
      if( user.socialId) item.socialId = user.socialId
      if( user.appId) item.appId = user.appId
      if( user.email) item.email = user.email
      if( user.flag) item.flag = user.flag
      if( user.forgotFlag) item.forgotFlag = user.forgotFlag
      if( user.mobileNo) item.mobileNo = user.mobileNo

      if( user.typeLogin) item.typeLogin = user.typeLogin

      let res:any = await this.postLogin(item)
      msg = res.msg
      resLast = res
      resList.push(res)
      if(!res.retry){
        this.setState({ loading: false });
        break;
      }
    }
    this.setState({ loading: false });
    resLast.msgE = msg


    if(resLast.resultCode === "20000" &&  browserName === 'WebKit' && !resLast.reload) {
      this.setState({buildingInfoLoading:true})
      window.location.href = window.location.origin + window.location.search +"&reload=true"
    }

    resLast.resList = resList
    console.log("resLast",resLast)
    return resLast;
  }






  postLogin(item){
    this.setState({postLoginItem:item})

    let msg:any = false
    // this.setState({ confirmloginLoging: true });
    return new Promise(async resolve => {
      // Notification.error({
      //     message:"",
      //     description:"postLogin",
      // });
      if(item){
        item.sessionId =  this.state.ChilliData.session ? this.state.ChilliData.session.sessionId :"";
      }

    item.check_privacy_policy = true
    Request.post("/login", item, {
      "x-access-id": this.state.buildingInfo.data.accessToken
          }).then(async (res: any) => {

            // Notification.error({
            //     message:"",
            //     description:JSON.stringify(res),
            // });
            if (res.resultCode && res.resultCode[0] === "2") {//) === "20000"
              this.setState({
                strUsername: res.resultData.strUsername,
                pappassword: res.resultData.pappassword
              });

              if(res.resultCode ==="20001" && item.check_privacy_policy){
                let msg_ = (i18n[this.props.locale]?i18n[this.props.locale].emailNotConfirmed:"")  + res.errorMessage
                Notification.success({
                  message:"",
                  description:msg_,
                });
              }

               if(res.resultCode && res.resultCode === "20005"){
                this.props.setOTPData({...res.resultData,item:item,cmd:0});
                this.props.history.push({ pathname:"/confirm-otp", search:this.props.location.search})
                return resolve({resultCode:res.resultCode,retry:false,msg:msg,userdata:false , logindata:{req:item,res:res}  });
              }
            // this.props.setOTPData({...d.resultData,item:data,cmd:0});
            // setTimeout(() => {
            //   this.props.history.push({ pathname:"/confirm-otp", search:this.props.location.search})
            // }, 500);

              console.log("typeLogin",item.typeLogin)
              let data:any = await this.logonChilli(res.resultData.strUsername,res.resultData.pappassword,res,item.typeLogin);
              console.log(data);

              // Notification.error({
              //     message:"",
              //     description:""+ data.reload,
              // });

              if(data.reload){
                try{
                  Cookies.set('strUsername', res.resultData.strUsername , { expires: 1 });
                }catch (e) {
                }


                this.setStrUsername({
                  strUsername:res.resultData.strUsername,
                  mac:this.state.buildingInfo.data.mac
                })

                  // alert("reload")
                  setTimeout(()=>{
                        this.setState({loading:true})

                        window.location.href = data.reloadurl;
                  },300)
                  return resolve({resultCode:"20000",reload:data.reload,retry:false,msg:msg  , logindata:{req:{},res:{}}});
              }

              // Notification.error({
              //     message:"",
              //     description:"xxxxx",
              // });
              console.log("data",data,res,data.clientState)
              if(data){
                this.setState({ ChilliData: data });
              }
              let isCaptiveIos = CommonService.isCaptiveIos();
              let originalURL = data && data.redir ? data.redir.originalURL : "";
              if (!data) {
                if (this.props.locale === "EN") {
                  msg = "Unsuccessful login Please try again."
                } else {
                  msg = "ล็อกอินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
                }
                // this.setState({ confirmloginLoging: false });
                return resolve({resultCode:res.resultCode,retry:false,msg:msg  , logindata:{req:item,res:res}});
              } else if (data.clientState !== 1) {

                let msg_trim =  data.message?data.message.trim():"";
                // console.log("msg_trim",msg_trim)
                msg = DictionaryService.msgChilli(msg_trim,this.props.locale)

                let code = DictionaryService.msgChilliCode(msg_trim,res.resultCode)

                let retry = ["Your maximum never usage time has been reached","You are already logged in - access denied"].indexOf(msg_trim) > -1?false:true;

                return resolve({resultCode:code,retry:retry,msg:msg ,pageError:["You are already logged in - access denied"].indexOf(msg_trim) > -1?"already":false , logindata:{req:item,res:res}});
              } else if (originalURL && originalURL.search('www.paysbuy.com/api_payment') > -1) {
                window.location.href = originalURL;

                return resolve({resultCode:res.resultCode,retry:false,msg:msg  , logindata:{req:item,res:res}});
              }
              // else if(this.state.buildingInfo.data.enableIframeLoginSuccess && this.state.buildingInfo.data.iframeLoginSuccessUrl){
              //   // buildingInfo.data.enableIframeLoginSuccess = true;
              //   // buildingInfo.data.iframeLoginSuccessUrl = 'https://www.donedeal.shop/';
              //
              //   return resolve({resultCode:res.resultCode,retry:false,msg:msg  , logindata:{req:item,res:res}});
              // }
              else if(this.state.buildingInfo.data.redirectURL && isCaptiveIos ){
                // if(isCaptiveIos){
                  window.location.reload(true)
                // }else{
                //   window.open(this.state.buildingInfo.data.redirectURL,"_blank");
                // }
                // window.location.href = this.state.buildingInfo.data.redirectURL;
                return resolve({resultCode:res.resultCode,retry:false,msg:msg  , logindata:{req:item,res:res}});
              }else {

                if(this.state.buildingInfo.data.redirectURL && ['http://www.ucimedia.co.th'].indexOf(this.state.buildingInfo.data.redirectURL) === -1){
                   window.open(this.state.buildingInfo.data.redirectURL,"_blank");
                }
                 let userdata = await this.getUserDetail();
                 console.log("userdata::",userdata);

                return resolve({resultCode:res.resultCode,retry:false,msg:msg,userdata:userdata , logindata:{req:item,res:res}  });
              }
          }else if(res.resultCode === "40410"){
             //CommonService.redirectChilli()
              let showPopup = true;
              this.setState({showPopup:showPopup})
              //  msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
              return resolve({resultCode:res.resultCode,retry:false,msg:false  , logindata:{req:item,res:res}});
         }else if(res.resultCode === "40101"){
              CommonService.redirectChilli()
              return resolve({resultCode:res.resultCode,retry:false,msg:msg  , logindata:{req:item,res:res}});
          } else {
            msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
            return resolve({resultCode:res.resultCode,retry:false,msg:msg  , logindata:{req:item,res:res}});
          }
        })
        .catch(e => {
          console.log(e);
          msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
          return resolve({resultCode:"50000",retry:false,msg:msg  , logindata:{req:item,res:e}});
        });
      });
  }

  async postLoginERegister(item){
    // console.log("postLogins",{flag:"social",...item})
    return this.postLogins({flag:"social",...item})
  }

  componentWillUpdate(nextProps, nextState){
    if(nextState.user != this.state.user){
      this.locationGo(nextState)
    }
  }

  locationGo(nextState){
    let loginList = ["/user-info","/edit-profile","/change-password","/user-info-iframe"]
    if(nextState.user){
      if(nextState.user.isShowIFrame && nextState.buildingInfo.data.enableIframeLoginSuccess){
        this.props.history.push({ pathname:"/user-info-iframe", search:this.props.location.search})
        setTimeout(() => {
          this.props.history.push({ pathname:"/user-info-iframe", search:this.props.location.search})
        }, 300);
      }else if(loginList.indexOf(this.props.location.pathname) === -1){
          console.log("nextState",nextState.user,this.props)
          if(nextState.user && (nextState.user.isShowIFrame) && nextState.buildingInfo.data.enableIframeLoginSuccess){
            this.props.history.push({ pathname:"/user-info-iframe", search:this.props.location.search})
          }else{
            this.props.history.push({ pathname:"/user-info", search:this.props.location.search});
          }
          setTimeout(() => {
            CommonService.scrollView()
          }, 100);
        }
      }else{
        if(loginList.indexOf(this.props.location.pathname) > -1){
          this.props.history.push({ pathname:"/", search: this.props.location.search});
        }
      }

  }

  setPopupAds(data){
    console.log("setPopupAds",data);
    document.getElementsByTagName("body")[0].style['overflow-y'] = `auto`;
    this.setState({popupAds:data})
  }

  setAgreement(){
    this.setState({showPopup:false},()=>{
      this.postLogin({ agreement:true,...this.state.postLoginItem})
    })

    // this.setState({agreementLoading:true})
    //
    // let params: any = queryString.parse(this.props.location.search);
    // // Request.post("/loginpagelog", {
    // //   "custGroupId":this.state.buildingInfo.data.custgroupId,
    // //   "usermac":chilliData.redir ? chilliData.redir.macAddress : params.mac,
    //   // console.log("buildingInfo",this.state.buildingInfo.data.mac);
    //
    // Request.post("/login/agreement", {
    //   sessionId: this.state.ChilliData.session ? this.state.ChilliData.session.sessionId :"",
    //   custGroupId: this.state.buildingInfo.data.custgroupId,
    //   mac:this.state.buildingInfo.data?this.state.buildingInfo.data.mac:null
    // }, {
    //   "x-access-id": this.state.buildingInfo.data.accessToken
    // }).then((res: any) => {
    //   this.setState({agreementLoading:false})
    //   // console.log("setUserrememberLogin",res);
    //   if(res.resultCode && res.resultCode === "20000"){
    //     this.setState({showPopup:false})
    //   }else{
    //     // Notification.error({
    //     //   message:"",
    //     //   description: res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
    //     //   resultCode:res.resultCode,
    //     //   // username:this.state.strUsername
    //     // });
    //     this.setState({showPopup:false})
    //   }
    // }).catch(e => {
    //   this.setState({agreementLoading:false})
    //   let errorMessage = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
    //   Notification.error({
    //       message:"",
    //       description:errorMessage ,
    //   });
    // });
  }

  render() {
   if (!this.state.buildingInfoLoading) {
      return <> <Loading />  </>;
    }else if (!this.state.buildingInfo.data) {
      const ErrorPage = createLazyContainer<any>(() => import(`./ErrorPage`),Loading,Error);

      return <ErrorPage
      res={this.state.buildingInfo}
      setLocaleData={this.setLocaleData}
      buildingInfo={this.state.buildingInfo}
      location={this.props.location}
      {...this.props}/>;

    }

    let url = this.props.match.url;
    let theme = "default";
      if(this.state.buildingInfo.enableFeatureLoginTemplate === 1){
        theme = this.state.buildingInfo.templateName? this.state.buildingInfo.templateName : "default";
      }

      console.log('this.state.customThemeJson',this.state.customThemeJson && this.state.customThemeJson.themeId === 99 && this.state.customThemeJson.color1 && this.state.customThemeJson.color2 ? 'true':'false');

       return <>
       <StyledDiv active={this.state.customThemeJson && this.state.customThemeJson.themeId === 99 && this.state.customThemeJson.color1 && this.state.customThemeJson.color2 ? 'true':'false'} color1={this.state.customThemeJson?this.state.customThemeJson.color1:null} color2={this.state.customThemeJson?this.state.customThemeJson.color2:null}>
        <div >
        <Modal
                  closable={false}
                  zIndex={99999}
                  className="top-md"
                  title={null}
                  visible={this.state.showPopup}
                  footer={[
                    <Button key="close" loading={this.state.agreementLoading} onClick={()=>{
                      this.setState({showPopup:false})
                    }}>
                      {this.props.locale==='EN'?'Close':'ปิด'}
                    </Button>,
                    <Button key="submit" type="primary" loading={this.state.agreementLoading} onClick={()=>{
                      this.setAgreement()
                    }}>
                      {this.props.locale==='EN'?'Accept':'ยินยอม'}
                    </Button>,
                  ]}
                >
                  <PopupAgreement
                  locale={this.props.locale}
                  buildingInfo={this.state.buildingInfo}
                  />
                </Modal>


        {this.state.announce?    <>
            <AnnouncePopup text={this.state.announce}/>
          </>    :null}
          <Switch>
                  <Route
                    path={`/user-info-iframe`}
                    render={() => (
                      <>
                    <LoginIfreamPage
                              img4={this.state.img4}
                              setCounterAds={this.setCounterAds}
                              counterAds={this.state.counterAds}
                              setLocaleData={this.setLocaleData}
                              buildingInfo={this.state.buildingInfo}
                              buildingInfoLoading={this.state.buildingInfoLoading}
                              location={this.props.location}
                              locale={this.props.locale}
                              history={this.props.history}
                              user={this.state.user}
                              setCountTime={this.setCountTime}
                              updateDetachAds={this.updateDetachAds}
                              socialTypeList={this.state.socialTypeList}
                              socialAuth={this.state.socialAuth}
                              reloadAuth={this.state.reloadAuth}
                              newBannerId={this.state.newBannerId}
                              setPopupAds={this.setPopupAds}
                              popupAds={this.state.popupAds}
                              />
                      <div
                        ref={r => (this.fakeAdBanner = r)}
                        style={{ height: '1px', width: '1px', visiblity: 'none', pointerEvents: 'none' }}
                        className="adBanner"
                      />
                    </>
                    )} />

         <App
          specialTheme={this.state.specialTheme}
          customThemeJson={this.state.customThemeJson}
          img4={this.state.img4}
          setLocaleData={this.setLocaleData}
          setCounterAds={this.setCounterAds}
          counterAds={this.state.counterAds}
          buildingInfo={this.state.buildingInfo}
          buildingInfoLoading={this.state.buildingInfoLoading}
          location={this.props.location}
          locale={this.props.locale}
          history={this.props.history}
          user={this.state.user}
          setCountTime={this.setCountTime}
          updateDetachAds={this.updateDetachAds}
          socialTypeList={this.state.socialTypeList}
          socialAuth={this.state.socialAuth}
          reloadAuth={this.state.reloadAuth}
          newBannerId={this.state.newBannerId}
          setPopupAds={this.setPopupAds}
          popupAds={this.state.popupAds}
        >
        <Switch>

            <Route path={`/privacypolicy`} render={() => (<div  style={{
              'background': '#fff',
              'padding': 8
            }}><PopupAgreement
              locale={this.props.locale}
              buildingInfo={this.state.buildingInfo}
              /></div>)}
                                                  />


          <Route path={`/usage-history`} render={() => (<InfoPage
                                                user={this.state.user}
                                                history={this.props.history}
                                                location={this.props.location}
                                                buildingInfo={this.state.buildingInfo}
                                                ChilliData={this.state.ChilliData}
                                                registerAction={{}} registerUser={{}}/>)}
                                                />
          <Route path={`/edit-profile`}  render={() => (<EditProfilePage
                                                history={this.props.history}
                                                location={this.props.location}
                                                buildingInfo={this.state.buildingInfo}
                                                ChilliData={this.state.ChilliData}
                                                registerAction={{}} registerUser={{}}/>)} />
          <Route path={`/change-password`}  render={() => (<ChangePasswordPage
                                                  history={this.props.history}
                                                  location={this.props.location}
                                                  buildingInfo={this.state.buildingInfo}
                                                  ChilliData={this.state.ChilliData}
                                                  user={this.state.user}
                                                  socialTypeList={this.state.socialTypeList}
                                                  registerAction={{}} registerUser={{}}/>)} />
        <Route
                  path={`/login-error`}
                  render={() => (
                    <LoginErrorPage
                    pageError={this.state.pageError}
                    history={this.props.history}
                    location={this.props.location}
                    buildingInfo={this.state.buildingInfo}
                    user={this.state.user}
                    locale ={this.props.locale}
                    strUsername={this.state.strUsername}
                    notificationError={this.state.notificationError}
                    />
                  )} />

        <Route
                  path={`/confirm-otp`}
                  render={() => (
                    <ConfirmOtpPage
                    pageError={this.state.pageError}
                    history={this.props.history}
                    location={this.props.location}
                    buildingInfo={this.state.buildingInfo}
                    user={this.state.user}
                    locale ={this.props.locale}
                    strUsername={this.state.strUsername}
                    notificationError={this.state.notificationError}
                    postLoginERegister={this.postLoginERegister}
                    updatePageError={this.updatePageError}
                    />
                  )} />
        <Route
            path={`/`}
            render={() => (
              <div>
                {(this.state.loading)?
                    <div id="login-" className="bg_login"   >
                      <div className="row">
                      <div className="col-md-12 header-text">
                            <div id="login" >
                          <div style={{ paddingBottom: 100,paddingTop: 100, display: 'table', margin: '0 auto' }}>
                              <div className="loader"></div>
                              {this.state.timeCount > 0?<>
                                <div style={{color:'#fff',fontWeight:600,textAlign:"center",fontSize:20}}> {this.state.timeCount}</div>
                              </>:null}
                          </div>
                          </div>
                      </div>
                      </div>
                      </div>
                  :null}
                <div style={!this.state.loading ? {} : { display: 'none' }}>

                  <LoginPage
                        specialTheme={this.state.specialTheme}
                        customThemeJson={this.state.customThemeJson}
                        confirmloginLoging={this.state.confirmloginLoging}
                        data={this.state.loginInfo}
                        loginInfoLoading={this.state.loginInfoLoading}
                        OnSubmitLogin={this.OnSubmitLogin}
                        OnSubmitLogOff={this.OnSubmitLogOff}
                        user={this.state.user}
                        locale={this.props.locale}
                        location={this.props.location}
                        postLoginERegister={this.postLoginERegister}
                        updatePageError={this.updatePageError}
                        loginmode={this.state.loginmode}
                        changeLoginmode={this.changeLoginmode}
                        buildingInfo={this.state.buildingInfo}
                        social={this.state.social}
                        history={this.props.history}
                        match={this.props.match}
                        freeUser={this.state.freeUser}
                      />
                </div>
              </div>

            )}

          />


          </Switch>
        </App>
        </Switch>
        </div>

        <div
        ref={r => (this.fakeAdBanner = r)}
        style={{ height: '1px', width: '1px', visiblity: 'none', pointerEvents: 'none' }}
        className="adBanner"
      />
      </StyledDiv>
      </>
  }
}


const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
  config: getCurrentConfig(state)
});

const setOTPData = (res)=>{
  return actions.setOTPData(res)
}

const setLocaleCustom = (res)=>{
      try {
        let haslocalStorage = !!localStorage;
        if (haslocalStorage) {
              localStorage.setItem('locale', res);
            }
          }
      catch (e) {
        // console.log(e);
      }
  return setLocale(res)
}
const mapDispatchToProps = {
  setLocaleCustom,
  setOTPData
}

export default connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(AppThemes)
