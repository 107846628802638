import React, { Component } from 'react';
import Agreement from '../../components/Agreement';
import i18n from "../../../../../i18n/index";
import CryptoJS from "crypto-js"; //
import AES from "crypto-js/aes";
// import { Button ,Form,Input } from 'antd';
import Notification  from '../../../../../services/notification.service';
// import { Translate } from 'react-redux-i18n'
import { Modal } from 'antd';
import RegisterForm from './RegisterForm';
import ForgotpasswordForm from './ForgotpasswordForm';
import SocialLoginForm from './SocialLoginForm';
import MobileLoginForm from './MobileLoginForm';
import {  Route ,Switch  } from "react-router-dom";
import Request  from '../../../../../services/request.service';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckboxM from '@material-ui/core/Checkbox';
import CheckBox from '@material-ui/icons/Stop';
import {isMobile,getUA,isSafari,isMobileSafari,browserName} from 'react-device-detect';
import queryString from 'query-string';
import AppConfig  from '../../../../../services/app.config';
import DictionaryService  from '../../../../../i18n/DictionaryService';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import { actions } from "../../../../../data/otpModel/action";
import { async } from 'q';
import { consoleSandbox } from '@sentry/utils';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';

import { FacebookProvider, Like,Page } from 'react-facebook';



import {Button ,DatePicker ,Form ,Icon,Input ,Dropdown,Menu,Typography,Divider } from 'antd';
import { getCurrentLocale } from "../../../../../data/utils";

declare let FB: any;
const axios = require('axios');

const LensChecked = withStyles({
  root: {
    color: 'rgb(248, 153, 50)',
  }
})(CheckBox);

const Lens = withStyles({
  root: {
    color: 'rgb(248, 248, 248)',
  }
})(CheckBox);
const Checkbox:any = withStyles({
  root: {
    color: 'white',
    padding: '0 0px',
    margin: '-8px -10px -14px 0px',
  },
  label: {
    textTransform: 'capitalize',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
})(CheckboxM);

type TranslateProps = {
  value: any,
  locale:any
}
type TranslateState = {}
class TranslatePage extends Component<TranslateProps, TranslateState> {
  constructor(props) {
    super(props)
  }
  render() {
    let locale = "EN";
    try{
      locale = this.props.locale;
    }catch(e){

    }
    return (<span> {DictionaryService.msg(this.props.value,locale)}</span>);
  }
}

const mapStateToProps0: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps0 = {}
const Translate:any = connect<any, any, {}>(mapStateToProps0, mapDispatchToProps0)(Form.create({})(TranslatePage));



type LoginFormProps = {

  OnSubmitLogin: any,
  postLoginERegister:any,
  OnSubmitLogOff:any,
  locale:any,
  loginmode:string,
  changeLoginmode:any,
  buildingInfo:any,
  social:any,
  user:any,
  history:any,
  location:any,
  freeUser:any,
  updatePageError:any,
  form:any,
  setOTPData:any,
  specialTheme:any,
  customThemeJson:any
}

type LoginFormState = {
  enableFacebookUserLikes:any,
  ERegisterPoupItem:any,
  facebookAuth:any,
  timeOutMinsTime:any ,
  resendOTPTime:any,
  dataMobileInit:any,
  itemMobile:any,
  username: any,
  password: any,
  remember: any,
  condition: any,
  conditionVisible: any,
  disabled:any,
  facebookData:any,
  facebookClick:boolean,
  page:string,
  registerUser:any,
  registerAction:any,
  userRemember:any,
  loading:any,
  loadingFb:any,
  rememberSocial:any,
  conditionSocial:any,
  fieldsLogin:any,
  typeMobile:any,
  loadingOtp:any,
  userRemember_mobileNo:any,
  mobileNo:any,
  modalFbLikeVisible:any,
  fbAppId:any
}



class LoginForm extends Component<LoginFormProps, LoginFormState> {
  formLogin:any;
  constructor(props) {
    super(props);
    let user: any = {};
    if(props.buildingInfo && props.buildingInfo.data){
      user.remember  = props.buildingInfo && props.buildingInfo.data && props.buildingInfo.data.loginShowChkRemember === 1? true : false;
    }


      let mobileNo = ''
      let mobileData:any = {}
      try {
        if(localStorage.getItem("user-mobile-data")){
          let decryptedBytes = AES.decrypt(localStorage.getItem("user-mobile-data"),"szlogin");
          let decryptedString = decryptedBytes.toString(  CryptoJS.enc.Utf8);
          mobileData = JSON.parse(decryptedString  || "");

          mobileNo = mobileData.mobileNo
        }
      } catch (error) {
      }




    if(props.freeUser){
      console.log("freeUser",props.freeUser);
      user.username = props.freeUser.username;
      user.password = props.freeUser.password;
    }
    let username = user.username || "";
    let password = user.password || "";
    let remember = user.remember || false;
    let condition = user.condition || true;

    let params: any = queryString.parse(this.props.location.search);
    let loadingFb = false;
    if(params.social){
        if(params.social === 'line'){

          let code = params.code
            this.props.changeLoginmode('social')
            loadingFb = true
            console.log("code",code)
            this.getLineLogin(code)
            // this.getFacebookLogin(code)

        }else {
          let code = params.code
            this.props.changeLoginmode('social')
            loadingFb = true
            let _params:any = params || {}
            delete _params.social
            delete _params.code

            this.props.history.push({ pathname:'', search:queryString.stringify(_params)});
            this.getFacebookLogin(code)
        }
      }

      if(this.props.user){
        this.props.history.push({ pathname:'user-info', search:this.props.location.search});
      }


        let userRemember:any = {}
        if(this.props.buildingInfo && this.props.buildingInfo.data){
          userRemember.remember  = this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.loginShowChkRemember === 1? true : false;
        }
        // try {
        //   if(localStorage.getItem("user-e-register")){
        //     let decryptedBytes = AES.decrypt(localStorage.getItem("user-e-register"),"szlogin");
        //     let decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
        //     userRemember = JSON.parse(decryptedString  || "");
        //   }
        // } catch (error) {}



        this.state = {
          enableFacebookUserLikes:false,
          fbAppId:'',
          modalFbLikeVisible: false,
          ERegisterPoupItem:{},
          facebookAuth:{},
          loadingOtp:false,
          timeOutMinsTime:null,
          resendOTPTime:null,
          typeMobile:'',
          dataMobileInit:{},
          itemMobile:{},
          loading:false,
          username: username,
          password: password,
          remember: remember,
          userRemember:userRemember,
          condition: condition,
          conditionVisible: false,
          facebookData:false,
          facebookClick:false,
          page:"login",
          registerUser:{},
          registerAction:{},
          disabled:false,
          loadingFb:loadingFb,
          rememberSocial:userRemember.remember,
          conditionSocial:condition,
          fieldsLogin:[],
          userRemember_mobileNo:'',
          mobileNo:mobileNo
        };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.onFacebookLogin = this.onFacebookLogin.bind(this);
      this.changePage = this.changePage.bind(this);
      this.submitRegister = this.submitRegister.bind(this);
      this.submitRegisterMobile = this.submitRegisterMobile.bind(this);

      this.handleSubmitERegister = this.handleSubmitERegister.bind(this);
      this.handleSubmitERegisterChecklogin = this.handleSubmitERegisterChecklogin.bind(this);

      this.submitForgotPassword = this.submitForgotPassword.bind(this);
      this.clearUserRemember = this.clearUserRemember.bind(this);
      this.setRegisterMobileData = this.setRegisterMobileData.bind(this);

      if(this.props.social.indexOf(1) > -1 && userRemember.remember){
      try {
        FB.getLoginStatus((res) => {
          console.log("getLoginStatus",res)
          if (res && res.authResponse) {
            console.log('Welcome!  Fetching your information.... ',res);
            FB.api('/me', {
              return_scopes: true,
              locale: 'en_US',
              fields: 'id,name,email,first_name,last_name,picture' //,birthday,gender
              },(response) => {
              console.log(response);
              this.setState({facebookData:{...res.authResponse,...response}},()=>{
                this.getLikeFacebook()
              })
            });
            } else {
            }
        }, true);
      }catch (e) {}
  }

    if(props.freeUser){

    }else{
      this.initGetUser()
    }

  }

  initGetUser(){
    this.getUsername({mac:this.props.buildingInfo.data.mac}).then((res)=>{
      console.log("setUserrememberLogin",res);

    if(res.user_0){
              let decryptedBytes = AES.decrypt(
                res.user_0.password,
                "szlogin"
              );
              let decryptedString = decryptedBytes.toString(
                CryptoJS.enc.Utf8
              );
              console.log("setUserrememberLogin",{
                ...res.user_0,password:decryptedString
              });

          this.setState({
            username:res.user_0.username,
            remember:res.user_0.remember,
            password:decryptedString
          })
    }
    if(res.user_1){
              let decryptedBytes = AES.decrypt(
                res.user_1.password,
                "szlogin"
              );
              let decryptedString = decryptedBytes.toString(
                CryptoJS.enc.Utf8
              );
              console.log("setUserrememberLogin",{
                ...res.user_1,password:decryptedString
              });
            this.setState({
              userRemember:{
                username:res.user_1.username,
                password:decryptedString
              },
                rememberSocial:res.user_1.remember,

            })
    }

  });
  }

  componentDidMount() {
    AppConfig.getConfig().then((config:any)=>{
          this.setState({fbAppId : config.fbAppId})
    })

  }

  getUsername(item){
    return Request.get("/login/rememberLogin", item, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then((res: any) => {
      console.log("res.resultData",res.resultData);

      if(res.resultData && (res.resultData.user_0 || res.resultData.user_1) ){

          let user_0:any = null
          if(res.resultData.user_0){
             user_0 = {
              username: res.resultData.user_0.remember?res.resultData.user_0.username:'',
              password: res.resultData.user_0.remember?res.resultData.user_0.password:'',
              remember: res.resultData.user_0.remember
            }
          }
          let user_1:any = null
          if(res.resultData.user_1){
             user_1 = {
               username: res.resultData.user_1.remember?res.resultData.user_1.username:'',
               password: res.resultData.user_1.remember?res.resultData.user_1.password:'',
              remember: res.resultData.user_1.remember
            }
          }
          return {
            user_0:user_0,
            user_1:user_1
          }


      }else{
          return false
      }
    })
  }


getLikeFacebook(){
  // https://api.facebook.com/method/pages.isFan?format=json&access_token=" . USER_TOKEN . "&page_id=" . FB_FANPAGE_ID
}
  async getLineLogin(code){
    this.setState({loadingFb:true})
    let config:any = await AppConfig.getConfig();
    let client_id = config.lineAppId
    let client_secret = config.lineClientSecret;//'';
    const data = {
      grant_type:'authorization_code',
      code:code,
      redirect_uri: ( window.location.origin + '/auth/line') , //encodeURIComponent
      client_id:client_id,
      client_secret:client_secret
    };
    // let lineUrl = "https://api.line.me"
    // let authProviderUrl = lineUrl + `/oauth2/v2.1/token`//'https://graph.facebook.com/oauth/access_token';
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: queryString.stringify(data) ,
      url:  'https://api.line.me/oauth2/v2.1/token'//'https://api.line.me/oauth2/v2.1/token',
    };

    Request.postForm(options).then((res)=>{
      let params: any = queryString.parse(this.props.location.search);
      let _params:any = params || {}
      delete _params.social
      delete _params.code
      this.props.history.push({ pathname:'', search:queryString.stringify(_params)});
    if(res && res.access_token){

       Request.getOther('https://api.line.me/v2/profile',{},{
        Authorization:`Bearer ${res.access_token}`
      }).then( (res2) =>{
        // let data2 = response2
        // this.setState({data:{...res,...data2}})

        const options2 = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: queryString.stringify({
            id_token:res.id_token,
            client_id:client_id,
            user_id:res2.userId
          }) ,
          url:  'https://api.line.me/oauth2/v2.1/verify'//'https://api.line.me/oauth2/v2.1/token',
        };

        Request.postForm(options2).then( (res3) =>{
          console.log(res3);
          this.onLineLogin({...res,...res2,userID:res2.userId,email:res3.email})
            // this.setState({data:{...data,...data2,...data3}})

        }).catch(e => {
            // this.setState({data:{...data,...data2}})
              this.onLineLogin({...res,...res2,userID:res2.userId})
        });

         // if(res2){
         //      this.onLineLogin({...res,...res2,userID:res2.userId})
         //  }else{
         //    Notification.error({
         //      message:"",
         //      description: "Line Authentication Failed",
         //    });
         //  }


      })
        }else{
          this.setState({loadingFb:false})
          Notification.error({
            message:"",
            description: "Line Authentication Failed",
          });
        }
      }).catch(e => {
        this.setState({loadingFb:false})
        Notification.error({
          message:"",
          description: "Line Authentication Failed",
        });
      });

  }

  async getFacebookLogin(code){
    this.setState({loadingFb:true})
    let config:any = await AppConfig.getConfig();
    let client_id = config.fbAppId
    let client_secret = config.fbClientSecret;//'';
    let redirect_uri =  encodeURIComponent(window.location.origin + "/auth/facebook")
    let facebookUrl = "https://graph.facebook.com"
    let authProviderUrl = facebookUrl + `/oauth/access_token`//'https://graph.facebook.com/oauth/access_token';
    let authParameters = {
        client_id: client_id,
        redirect_uri: redirect_uri,
        client_secret: client_secret,
        code: code
    };
    let params_:any = [];
    for (let k in authParameters) {
        params_.push(k + '=' + authParameters[k]);
    }
    let authOpenURI = authProviderUrl + '?' + params_.join('&');
    Request.getOther(authOpenURI).then((res)=>{

    if(res && res.access_token){
          Request.getOther( facebookUrl + `/me?fields=id,name,email,birthday,gender,first_name,last_name,picture&access_token=${res.access_token}`).then((res2)=>{
            this.setState({loadingFb:false})
            if(res2 && res2.id){
              console.log(res2,this.props);
              this.setState({loading:false,facebookAuth:{...res,...res2,accessToken:res.access_token}})
              this.onFacebookLogin({...res,...res2,userID:res2.id})
              if(this.state.rememberSocial ){
                try {
                  // localStorage.setItem('user-e-register',AES.encrypt(JSON.stringify({"remember":this.state.rememberSocial}), "szlogin"));
                }catch (e) {

                }
              }
            }else{
              Notification.error({
                message:"",
                description: "Facebook Authentication Failed",
              });
            }
          }).catch(e => {
            this.setState({loadingFb:false})
            Notification.error({
              message:"",
              description: "Facebook Authentication Failed",
            });
          });
        }else{
          this.setState({loadingFb:false})
          Notification.error({
            message:"",
            description: "Facebook Authentication Failed",
          });
        }
      }).catch(e => {
        this.setState({loadingFb:false})
        Notification.error({
          message:"",
          description: "Facebook Authentication Failed",
        });
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.OnSubmitLogin(this.state);
  }

  async ERegisterPoup(item){

    let ddd =  await this.ERegister(item);
    let d = ddd.data ? ddd.data : {};
    let logindata:any = {}
    if(d.logindata && d.logindata.res && d.logindata.res.resultData){
      logindata = d.logindata.res.resultData
    }

   let label = (d.resultCode && d.resultCode[0] ==="2"?"Successfully":"Error") +" : "+d.resultCode+" $ "+( +d.resultCode && d.resultCode[0] ==="2" ?"Success": ((d.msg ? d.msg :d.msgE?d.msgE:d.pageError)))
   ReactGA.event({
     category: 'Login Page',
     action: logindata.userType?logindata.userType:"",
     label: label
   });
   console.log("ReactGA.event",{
     category: 'Login Page',
     action: logindata.userType?logindata.userType:"",
     label: label
   },d)


    if(ddd.msgE){
      Notification.error({
        description: ddd.msgE,
        resultCode: ddd.resultCode
        });
    }
  }

  getFacebookApi(){
    let access_token = this.state.facebookAuth.accessToken;
    let facebookUrl = "https://graph.facebook.com";
    return axios.get(facebookUrl + `/me/likes/${this.props.buildingInfo.data.fbPageRef}?access_token=${access_token}`).then(response => response.data)

  }

  async setModalFbVisible(data){
    // alert("xxxx")
    let res = await this.getFacebookApi()
    if((res.data && res.data.length > 0) || !this.state.enableFacebookUserLikes){
      this.setState({modalFbLikeVisible:false},()=>{
          this.ERegisterPoup(this.state.ERegisterPoupItem);
      })

    }else{
      Notification.error({
        message:"",
        description: DictionaryService.msg("v_facebook_like",this.props.locale),
        });
      return;
    }
  }

  async onFacebookLogin(r){
    console.log("onFacebookLogin",r)
    this.setState({loadingFb:true})
    if(r.userID){
      let item = {
          password:r.userID,
          socialId:2,//"facebook",
          email:r.email,
          username:r.email,
          userName:r.email,
          appId:r.userID,
      }
      this.setState({facebookData:r},()=>{
        // this.getLikeFacebook()
      })
        let isReg:any =  await this.checkIsRegister({
          socialId:2,
          email:r.email,
          appId:r.userID,
      })
    this.setState({loadingFb:false})
    // if(){
      this.setState({enableFacebookUserLikes:isReg.enableFacebookUserLikes})
    // }
    console.log("checkIsRegister",isReg)
    if(isReg.resultCode === "40305"){
      this.setState({ERegisterPoupItem:item},()=>{
        if(this.props.buildingInfo && this.props.buildingInfo.data &&
          this.props.buildingInfo.data.enableLikePage &&
          this.props.buildingInfo.data.fbPageRef &&
          isReg.enableFacebookUserLikes){
            this.getFacebookApi().then(r=>{
              if(r.data && r.data.length > 0){
                  this.ERegisterPoup(item);
              }else{
                this.setState({modalFbLikeVisible:true})
              }
            }).catch(e => {
              this.ERegisterPoup(item);
            });
        }else{
          this.ERegisterPoup(item)
        }

      })
      // this.ERegisterPoup(item)

      // alert("xxxx")
    }else if (isReg && isReg.resultCode && isReg.resultCode[0] === "2" ){
      let register = {
        appId:r.userID,
        socialType:"facebook",
        socialId:2,
        email:r.email,
        first_name:r.first_name,
        last_name:r.last_name,
        // birthday:moment(r.birthday,"MM/DD/YYYY").toDate(),
        gender:r.gender === 'male'?1:0,
        image:r.picture && r.picture.data ?r.picture.data.url :null
      }
      this.setState({registerUser:register,registerAction:{username:'none',password:'none'}})
      this.changePage("register")
    }else if (isReg.resultCode === "20003"){
      let item = {
        appId:r.userID,
        socialType:"facebook",
        socialId:2,
        email:r.email,
        firstName:r.first_name,
        surName:r.last_name,
        birthday:r.birthday ? moment(r.birthday,"MM/DD/YYYY") : moment().add("years",-20).toDate(),
        gender:r.gender === 'male'?0:1,
        image:r.picture && r.picture.data ?r.picture.data.url :null
      }
      this.submitRegister(item);
    }else{
        let msg = isReg.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
            message:"",
            description: msg,
            resultCode: isReg.resultCode
        });
    }
    }else{
      this.setState({loadingFb:false})
    }
  }


  async onLineLogin(r){
    console.log("onLineLogin",r)
    this.setState({loadingFb:true})
    if(r.userID){
      let item = {
          password:r.userID,
          socialId:3,//"facebook",
          email:r.email,
          username:r.email,
          userName:r.email,
          appId:r.userID,
      }
      // this.setState({facebookData:r})
        let isReg:any =  await this.checkIsRegister({
          socialId:3,
          email:r.email,
          appId:r.userID,
      })
    this.setState({loadingFb:false})
    if(isReg.resultCode === "40305"){
     let ddd =  await this.ERegister(item);
      let d = ddd.data ? ddd.data : {};
      let logindata:any = {}
      if(d.logindata && d.logindata.res && d.logindata.res.resultData){
        logindata = d.logindata.res.resultData
      }

     let label = (d.resultCode && d.resultCode[0] ==="2"?"Successfully":"Error") +" : "+d.resultCode+" $ "+( d.resultCode[0] ==="2" ?"Success": ((d.msg ? d.msg :d.msgE?d.msgE:d.pageError)))
     ReactGA.event({
       category: 'Login Page',
       action: logindata.userType?logindata.userType:"",
       label: label
     });
     console.log("ReactGA.event",{
       category: 'Login Page',
       action: logindata.userType?logindata.userType:"",
       label: label
     },d)


      if(ddd.msgE){
        Notification.error({
          description: ddd.msgE,
          resultCode: ddd.resultCode
          });
      }
    }else if (isReg && isReg.resultCode && isReg.resultCode[0] === "2" ){
      let register = {
        appId:r.userID,
        socialType:"line",
        socialId:3,
        email:r.email,
        first_name:r.first_name,
        last_name:r.last_name,
        // birthday:moment(r.birthday,"MM/DD/YYYY").toDate(),
        gender:r.gender === 'male'?1:0,
        image:r.picture && r.picture.data ?r.picture.data.url :null
      }
      this.setState({registerUser:register,registerAction:{username:'none',password:'none'}})
      this.changePage("register")
    }else if (isReg.resultCode === "20003"){
      let item = {
        appId:r.userID,
        socialType:"line",
        socialId:3,
        email:r.email,
        firstName:r.first_name,
        surName:r.last_name,
        birthday:r.birthday ? moment(r.birthday,"MM/DD/YYYY") : moment().add("years",-20).toDate(),
        gender:r.gender === 'male'?0:1,
        image:r.picture && r.picture.data ?r.picture.data.url :null
      }
      this.submitRegister(item);
    }else{
        let msg = isReg.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
            message:"",
            description: msg,
        });
    }
    }else{
      this.setState({loadingFb:false})
    }
  }

  changePage(page){
    if(page === 'login'){
      this.props.history.push({ pathname:"/", search:this.props.location.search});
    }else{
      this.props.history.push({ pathname:page, search:this.props.location.search});
    }
  }

  // maskOnlyUsername(data){
  //   let str = data?data:''
  //   let _str = ''
  //   var regex = /[ก-๙]/;
  //   for (let index = 0; index < str.length; index++) {
  //     const element = str[index];
  //     console.log(element,regex.test(element));
  //
  //     if( !regex.test(element) ) {
  //         _str+=element
  //     }
  //   }
  //   console.log(_str  , str);
  //   return  _str
  // }

  changePageWithData(page,data){
    //this.props.location.search
      let item = queryString.parse(this.props.location.search);
      item = {...item,...data}
      this.props.history.push({ pathname:page, search: queryString.stringify(item)});
  }

  goRegister(registerUser = {}){
    console.log("this.props.registerUser",registerUser)

    console.log("this.props.registerUser",registerUser)
    this.setState({registerUser:registerUser,registerAction:{}})
    this.changePage("register")
  }


  checkIsRegister(item){
    return Request.get("/register/checkregis", item, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    });
  }

  submitRegister(item){
    // item = {...item}
    console.log("submitRegister-item",item)
    return Request.post("/register", item, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then(async (res)=>{
       if ( res && res.resultCode && res.resultCode[0] === "2" ) {
        try {
          // localStorage.removeItem('user-e-register')
          this.setUsernameCls();
        }catch (e) {}
        this.setState({userRemember:{}})
        this.changePage("login")
        if(item.socialId === 2 || item.socialId === 3){
        let data_ = await this.ERegister({
              password:item.appId,
              socialId:item.socialId,
              email:item.email,
              username:item.email,
              userName:item.email
          })
          return {
            type:data_ && data_.resultCode && data_.resultCode[0] === "2"?"success":"error",
            description: this.getMsgRegisterLogin(data_,res),
            data:data_
          }
        }else{
          let data_ = await this.ERegister({
            socialId:1,
            email:item.email,
            username:item.email,
            password:item.password,
            remember:true,
            typeLogin:'register'
        })
        return {
          resultCode: data_.resultCode,
          type:data_ && data_.resultCode && data_.resultCode[0] === "2"?"success":"error",
          description: this.getMsgRegisterLogin(data_,res),
          data:data_
        }
      }
      }else{
        let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        return {resultCode: res.resultCode, type:"error",description: msg,data:{
          resultDescription:msg
        }}
      }
      }).catch(e => {
        console.log(e);
        let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        return {type:"error",description: msg,data:{
          resultDescription:msg
        }}
      });

  }

  submitRegisterMobile(item){

    return Request.post("/register/mobile", item, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then(async (res)=>{
       if ( res && res.resultCode && res.resultCode[0] === "2" ) {
        try {
          localStorage.removeItem('user-mobile')
        }catch (e) {}
        this.setState({userRemember:{}})
        this.changePage("login")

        return {
          resultCode: res.resultCode,
          type:res && res.resultCode && res.resultCode[0] === "2"?"success":"error",
          description:res.errorMessage,
          data:res
        }
      // }
      }else{
        let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        return {resultCode: res.resultCode, type:"error",description: msg,data:{
          resultDescription:msg
        }}
      }
      }).catch(e => {
        console.log(e);
        let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        return {type:"error",description: msg,data:{
          resultDescription:msg
        }}
      });

  }



  getMsgRegisterLogin(data_,res){
    // console.log("getMsgRegisterLogin",data_,res)
    if(data_.resultCode !=="20000" && data_.resultCode && data_.resultCode[0] === "2"){ // res && res.resultCode && res.resultCode[0] === "2"
      let msg = data_.errorMessage
      if(data_.resultCode ==="20001"){
        msg = (i18n[this.props.locale]?i18n[this.props.locale].successfullyRegisteredAndLogged:"")  + data_.errorMessage
      }
      return msg
    }
    return data_.resultCode && data_.resultCode && data_.resultCode[0] === "2" ? res.errorMessage:data_.errorMessage
  }

  checkforgot(item){
    return Request.get("/register/checkforgot", item, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then(async (res)=>{
      if(res && res.resultCode && res.resultCode[0] === "2"){

      }else{
        let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
          resultCode:res.resultCode
        });
      }
      return res
    }).catch(e => {
      console.log(e);
        let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
        });

      return {}
    });
  }

  async submitForgotPassword(item){

    let confirm = await this.checkforgot({ email:item.email,socialId:1})
    if(!confirm || !confirm.resultCode || !(confirm.resultCode && confirm.resultCode[0] ==='2')){
      return ;
    }

    let data = await this.props.postLoginERegister({flag:'social',username:item.email, email:item.email,socialId:1,forgotFlag:1})
      if(data.userdata && data.logindata.res && data.logindata.res && data.userdata.res.resultCode && data.userdata.res.resultCode[0] === "2"){// === "20000"
        Notification.success({
          message:"",
          description: data.logindata && data.logindata.res ? data.logindata.res.errorMessage : "",
          });
          return {
            type:"success",
            message:"",
            description: data.logindata && data.logindata.res ? data.logindata.res.errorMessage : "",
          };
    }
      if(data.msgE){
        Notification.error({
          message:"",
          description:data.msgE,
          resultCode:data.resultCode
        });
      }

      if(data && data.pageError){
        this.props.updatePageError(data.pageError)
        this.props.history.push({ pathname:"/login-error", search:this.props.location.search})
      }

    return {
      type:"error",
      message:"",
      description: data.userdata && data.userdata.res?data.userdata.res.errorMessage:''
    };
  }

  clearUserRemember(){
    // try {
    //     localStorage.removeItem('user-e-register')
    //   }catch (e) {}
    this.setUsernameCls();
    this.setState({userRemember:{}})
  }

  async handleSubmitERegister(item){
    return this.ERegister({email:item.username,socialId:1,...item})
  }

  handleSubmitERegisterChecklogin(item){
    return Request.post("/checklogin", {
      custGroupId: this.props.buildingInfo.data.custgroupId,
      // challenge: this.props.ChilliData.challenge,
      flag:'social',
      email:item.username,
      userName:item.username,
      socialId:1,...item
    }, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then(async (res)=>{
      this.setState({loadingOtp:false})
      if(res && res.resultCode && res.resultCode[0] === "2"){

      }else{
        // Notification.error({
        //   message:"",
        //   description: res.errorMessage,
        //   resultCode : res.resultCode
        // });
      }
      return res
    }).catch(e => {
      this.setState({loadingOtp:false})
      console.log(e);
        let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
        });
      return {}
    });

    // return this.ERegister({email:item.username,socialId:1,...item})
  }

  setUsername(item){
    console.log("item>>>",item);
    Request.post("/login/rememberLogin", item, {
      "x-access-id":  this.props.buildingInfo.data.accessToken
    }).then((res: any) => {
      console.log("setUserrememberLogin",res);
    })
  }

  setUsernameCls(){

    this.setUsername({
       "mac": this.props.buildingInfo.data.mac,
       "username": '',
       "password": '',
       "type": 1,
       "remember":false
    })
  }

  async ERegister(item){
  let data = await this.props.postLoginERegister({...item})
  if(item.socialId === 1){
    if(data.userdata && data.logindata.res && data.logindata.res && data.logindata.res.resultData){
      console.log("item>>>",item);

      if(item.remember){
        try {
          this.setUsername({
             "mac": this.props.buildingInfo.data.mac,
             "username": item.username,
             "password": item.password?AES.encrypt(item.password, "szlogin").toString():'',
             "type": 1,
             "remember":item.remember
          })
        }catch (e) {

        }
      //   // try {
      //   //   let res = data.logindata.res.resultData || {}
      //   //   let d = {
      //   //     "user":{ ...res },
      //   //     "username":item.username,
      //   //     "password":item.password,
      //   //     "remember":item.remember
      //   //    }
      //   //    console.log(d,"ERegister",item)
      //   //    this.setState({userRemember:d})
      //   //   let haslocalStorage = !!localStorage;
      //   //   if (haslocalStorage) {
      //   //           localStorage.setItem('user-e-register',AES.encrypt(JSON.stringify(d), "szlogin"));
      //   //       }
      //   //   }  catch (e) {
      //   //   console.log(e)
      //   // }
      }else{
        this.setUsernameCls();
      //   // try {
      //   //   localStorage.setItem('user-e-register',AES.encrypt(JSON.stringify({"remember":false}), "szlogin"));
      //   // }catch (e) {}
      }
    }else{
        this.setUsernameCls();
      // try {
      //   // localStorage.removeItem('user-e-register')
      // }catch (e) {
      //
      // }
    }
  }
  if(data && data.pageError){
    this.props.updatePageError(data.pageError)
    this.props.history.push({ pathname:"/login-error", search:this.props.location.search})
  }
  let dataRes:any  = {}
  let edata:any = false;
  if(data.resList && data.resList.length)
  for (let index = 0; index < data.resList.length; index++) {
    const e = data.resList[index];
      if(e && e.resultCode && e.resultCode!=="20000" && e.resultCode[0] === "2"){
        edata = e
        break;
      }
  }
  if(edata){
    dataRes = edata.logindata.res
  }else if(data.userdata){
    dataRes = data.userdata.res
  }else{
    if(data.logindata.res)
    dataRes = data.logindata.res
  }
  if(data.msgE)
    dataRes.msgE = data.msgE

  if(data.resultCode !== "20000"){
    dataRes.resultCode = data.resultCode;
  }
  if(dataRes){
      dataRes.data = data
  }else{
    dataRes = {}
  }

  return dataRes
  }

  loginMobile(){
    this.props.changeLoginmode("mobile")
  }

  loginEregiter(){
    this.props.changeLoginmode("eregister")
  }

  loginSocial(){
    if(this.props)
    this.props.changeLoginmode("social")
  }


   async loginLine(){
     // if (this.state.conditionSocial == false) {
     //   if (this.props.locale === "EN") {
     //    Notification.error({
     //       message:"",description:`You must accept the usage agreement by clicking on the checkbox below the password form to login.`
     //     });
     //   } else {
     //     Notification.error({
     //       message:"",description:"ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้",
     //     });
     //   }
     //   return false;
     // }

     let config:any = await AppConfig.getConfig();
     let params = queryString.parse(this.props.location.search);
     let arr = Object.keys(params).map(function(key) {
       return key+"="+params[key];
     });
     let redirect_uri = encodeURIComponent( window.location.origin + '/auth/line') //'https://szlogin.scapp.work/auth/facebook'
     let state = arr.join(",")
     let client_id = config.lineAppId
     let face = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=email%20profile%20openid&state=${state}`
     //,user_gender,user_birthday
     // this.props.history.push('/')
     this.setState({loadingFb:true})
     console.log("face",face)
     window.location.href = face
     setTimeout(() => {
       this.setState({loadingFb:false})
     }, 10000);
   }


 async loginFB(){
  // if (this.state.conditionSocial == false) {
  //   if (this.props.locale === "EN") {
  //    Notification.error({
  //       message:"",description:`You must accept the usage agreement by clicking on the checkbox below the password form to login.`
  //     });
  //   } else {
  //     Notification.error({
  //       message:"",description:"ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้",
  //     });
  //   }
  //   return false;
  // }
  let errorFacebook = false;
  try {
    console.log("FB",FB);
    if(!FB){
      errorFacebook = true;
    }
  } catch (error) {
    console.log(error);
    errorFacebook = true;
  }


  if(errorFacebook){
    //ระบบ social login ของสถานที่นี้ยังมีข้อมูลไม่ครบถ้วน กรุณาติดต่อ call center
    if (this.props.locale === "EN") {
         Notification.error({
            message:"",
            resultCode:"C50011",
            description:`The social login (facebook) system of this place is not yet fully installed. Please contact the call center.`
          });
    } else {
        Notification.error({
          message:"",
          resultCode:"C50011",
          description:"ระบบ social login (facebook) ของสถานที่นี้ยังติดตั้งไม่ครบถ้วน กรุณาติดต่อ call center",
        });
    }
    Sentry.captureMessage('/By Pass Facebook|'+moment());
    return ;
  }

  // Notification.error({
  //   message:"",
  //   description:getUA + "["+browserName+"]",
  // });
  // console.log("getUA",getUA,isSafari)

    if (isMobile || browserName === 'WebKit' ) { //  || true

      let config:any = await AppConfig.getConfig();
      let params = queryString.parse(this.props.location.search);
      let arr = Object.keys(params).map(function(key) {
        return key+"="+params[key];
      });
      let redirect_uri = window.location.origin + "/auth/facebook"//'https://szlogin.scapp.work/auth/facebook'
      let state = arr.join(",")
      let client_id = config.fbAppId
      let face = `https://www.facebook.com/v4.0/dialog/oauth?fields=id,name,email,birthday,gender,first_name,last_name,picture&locale=en_US&client_id=${client_id}&scope=user_likes,public_profile,email&redirect_uri=${redirect_uri}&state=${state}`
      //,user_gender,user_birthday
      this.setState({loadingFb:true})
      window.location.href = face
      setTimeout(() => {
        this.setState({loadingFb:false})
      }, 10000);
  }else{



    FB.login((res) => {
      // Request.post("/register/stamplog", {
      //   cmd:'facebooklogin',
      //   status:'login',
      //   data:res
      // })
      this.setState({loading:true})
      if (res.authResponse) {
      console.log('Welcome!  Fetching your information.... ',res);
      FB.api('/me', {
        return_scopes: true,
        locale: 'en_US',
        fields: 'id,name,email,first_name,last_name,picture' //,birthday,gender
        },
        (response) => {

          // Request.post("/register/stamplog", {
          //   cmd:'facebooklogin',
          //   status:'me',
          //   data:response
          // })

          console.log(response);
          this.setState({loading:false,facebookAuth:{...res.authResponse,...response}})
          this.onFacebookLogin({...res.authResponse,...response})
          // if(this.state.rememberSocial ){
            // localStorage.setItem('user-e-register',AES.encrypt(JSON.stringify({"remember":this.state.rememberSocial }), "szlogin"));
          // }
        });
      } else {
      console.log('User cancelled login or did not fully authorize.');
      this.setState({loading:false})
      }
  },{scope: 'public_profile,user_likes,email',}); //user_gender,user_birthday,
  }

  }

  handleChangeCheckBok = name => event => {
    this.setState({ ...this.state, [name]: event.target.checked });
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.locale !== this.props.locale){
    }
  }

  checkregisMobile  = (item) => {
    return Request.get("/register/mobile/checkregis", item, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then(async (res)=>{

      if(res && res.resultCode && res.resultCode[0] === "2" || res.resultCode === '40408' || res.resultCode === '40316'){

        try {
          if(this.state.remember){
            localStorage.setItem('user-mobile-data',AES.encrypt(JSON.stringify({...item}), "szlogin"));
            console.log("user-mobile-data",{...item})
          }else{
            localStorage.removeItem('user-mobile-data')
            this.setState({mobileNo:''})
          }

        }catch (e) {

        }

      }else{
        let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
          resultCode:res.resultCode
        });
      }
      return res
    }).catch(e => {
      console.log(e);
        let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
        });

      return {}
    });
  };

  resendOTPmobile = (item) => {
    if(!moment().isAfter(this.state.resendOTPTime)){
      let errmsg = "You can click new OTP again at "
      if(this.props.locale === 'TH'){
        errmsg = "ท่านสามารถกด รับรหัส OTP ใหม่ได้ในเวลา "
      }

      Notification.error({
        message:"",
        description: errmsg + (this.state.resendOTPTime?this.state.resendOTPTime.format('HH:mm:ss'):null),
        });
      return ;
    }

    this.setState({loadingOtp:true})
    return Request.post("/register/mobile/resendOTP", item, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then(async (res)=>{
      this.setState({loadingOtp:false})
      if(res && res.resultCode && res.resultCode[0] === "2"){
         this.setState({dataMobileInit:res.resultData,
           timeOutMinsTime: moment().add(res.resultData.timeOutMins, 'minutes').format('HH:mm:ss'),
           resendOTPTime: moment().add(res.resultData.waitingMinute, 'minutes')//.format('HH:mm:ss'),
         },()=>{
           this.props.form.setFieldsValue({code:''})
         })
      }else{
        let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
          resultCode:res.resultCode
        });
      }
      return res
    }).catch(e => {
      this.setState({loadingOtp:false})
      console.log(e);
        let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
        });

      return {}
    });
  };

  confirmOTPmobile = (item) => {
    this.setState({loadingOtp:true})
    return Request.post("/register/mobile/confirmOTP", item, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then(async (res)=>{
      this.setState({loadingOtp:false})
      if(res && res.resultCode && res.resultCode[0] === "2"){

      }else{
        let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
          resultCode:res.resultCode
        });
      }
      return res
    }).catch(e => {
      this.setState({loadingOtp:false})
      console.log(e);
        let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
        });

      return {}
    });
  };

  replaceAt(str, index, replacement) {
   if(!str){
     return "";
   }
   else if(index >= str.length ){
     return str;
   }
   else{
     return str.substr(0, index) + replacement+ str.substr(index + replacement.length);
   }
 }

  setRegisterMobileData(data){
    console.log("setRegisterMobileData",data)
    this.setState({typeMobile:'resendOTP',
    dataMobileInit: data.data && data.data.resultData?data.data.resultData:{},
    timeOutMinsTime: moment().add(data.data.resultData.timeOutMins, 'minutes').format('HH:mm:ss'),
    resendOTPTime: moment().add(data.data.resultData.waitingMinute, 'minutes')//.format('HH:mm:ss'),
  })
  }

  maskOnlyUsername(data){
    let str = data?data:''
    let _str = ''
    var regex = /[ก-๙]|\./;
    for (let index = 0; index < str.length; index++) {
      const element = str[index];
      console.log(element,regex.test(element));

      if( !regex.test(element) ) {
          _str+=element
      }
    }
    console.log(_str  , str);
    return  _str
  }

  mobileForm(){
    const { getFieldDecorator } = this.props.form;
    let { fieldsLogin } = this.state
    let mobileNo = this.state.dataMobileInit?this.state.dataMobileInit.mobileNo:''
    mobileNo = this.replaceAt(mobileNo,0,"xxxxxx")
    let errmsg = "One time Password (OTP) has been sent to your mobile no.  "+mobileNo+", please enter 6-digit OTP here to confirm your identity";
    if(this.props.locale === "TH"){
      errmsg = " รหัสผ่านครั้งเดียว (OTP) ถูกส่งไปยังเบอร์มือถือ "+mobileNo+" ของคุณแล้ว โปรดป้อนรหัส OTP 6 ตัว ที่นี่เพื่อยืนยันตัวตน";
    }


    return  <div className="row">
    <div className="col-md-12">
    <Form onSubmit={async (event)=> {
          event.preventDefault();



          // if (this.state.conditionSocial == false) {
          //   if (this.props.locale === "EN") {
          //    Notification.error({
          //       message:"",description:`You must accept the usage agreement by clicking on the checkbox below the password form to login.`
          //     });
          //   } else {
          //     Notification.error({
          //       message:"",description:"ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้",
          //     });
          //   }
          //   return false;
          // }



          this.props.form.validateFields(async (err, user) => {
            if (!err) {
              if(this.state.typeMobile === 'resendOTP'){
                // console.log("this.state.dataMobileInit",this.state.dataMobileInit)
              let data_confirm:any = await this.confirmOTPmobile({codeOTP:user.code,cmd:1,...this.state.dataMobileInit});
              if(data_confirm.resultCode === '20000'){
                this.setState({loadingOtp:true})
                let data_login = await this.props.postLoginERegister({mobileNo:this.state.dataMobileInit.mobileNo,flag:'mobile',forgotFlag:0})
                this.setState({loadingOtp:false})
                // console.log("data",data_login)
                if(data_login.resultCode === '20000'){


                }else{
                  if(data_login.msgE){
                    Notification.error({
                      description: data_login.msgE,
                      resultCode: data_login.resultCode
                      });
                  }
                  this.setState({typeMobile:''})
                  this.props.changeLoginmode("social")
                }
              }
              }else{
                let userPost = {...user,flag:'mobile'}
                console.log('Received values of form: ', user,this.state,userPost);
                //loading:true,
                this.setState({loadingOtp:true})
                this.setState({itemMobile:user})
               let data_checkregis:any = await this.checkregisMobile(user);
               this.setState({loadingOtp:false})
               if(data_checkregis.resultCode === '20000'){

                  let register = {
                    mobileNo:user.mobileNo,
                    socialType:"mobile",
                  }
                  this.setState({registerUser:register,registerAction:{username:'none',password:'none' ,'email':'none_required'}})
                  this.changePage("register")
               }else if(data_checkregis.resultCode === "40408" || data_checkregis.resultCode === '40316'){
                 // let data:any = await this.ERegister(userPost)
                 this.setState({typeMobile:'resendOTP',
                 dataMobileInit:data_checkregis.resultData,
                 timeOutMinsTime: moment().add(data_checkregis.resultData.timeOutMins, 'minutes').format('HH:mm:ss'),
                 resendOTPTime: moment().add(data_checkregis.resultData.waitingMinute, 'minutes')//.format('HH:mm:ss'),
               })
               }
              }

           // this.setState({loading:false})
         }
       });


    }} className="login-form" style={{marginTop:8,marginBottom:2}} autoComplete="off">
    {
      this.state.typeMobile === 'resendOTP'?
      <>

      {this.state.dataMobileInit && this.state.dataMobileInit.refCode?
        <div className="row" style={{color:"#fff"}}>
        <div className="col-12 header-text" >
          <Translate value="otp_x" />
            {/*<Translate value="mOtp2" />( {this.state.dataMobileInit.mobileNo} )*/}
        </div>
        <div className="col-md-12 col-12 text-center" style={{marginTop: 10}}>
          {errmsg}
          {/*<Translate value="mOtp1" />*/}
        </div>
        <div className="col-md-12 col-12 text-center" >
            {/*<Translate value="mOtp2" />( {this.state.dataMobileInit.mobileNo} )*/}
        </div>
        <div className="col-md-12 col-12 text-center" >
        <Translate value="mOtp3" />  : {this.state.dataMobileInit.refCode}
        </div>

        {
          this.state.timeOutMinsTime?
          <div className="col-md-12 col-12 text-center" >
           <Translate value="mOtp4" />: {this.state.timeOutMinsTime}
          </div>:null
        }



        <div className="col-md-12 col-12 text-center" >

        <Form.Item style={{width: 150,display: 'table',margin: '0 auto',marginTop: 15}}>
          {getFieldDecorator('code', {
            rules: [
              {
                required: true,
                message: i18n[this.props.locale].mOtp1,
              },
              {
                min: 6,
                message: i18n[this.props.locale].mOtp1 ,
              }

            ],
          })(<Input placeholder="" maxLength={6} style={{width: '100%',textAlign: 'center'}}/>)}
        </Form.Item>


        </div>

        <div className="col-md-12  col-12" style={{marginTop: 12}} >
        <div className="row" >
          <div className="col-6" >
              {/*  disabled={(hasErrors(getFieldsError())) ||  (!$isFieldsTouched) } */}
              <Button id="updateprofile-submit" type="primary" htmlType="submit"
                disabled={this.state.loadingOtp}
                className="login-form-button bt-singin" style={{
                width: '100%',
                background: '#f89932',
                borderColor: '#f89932',marginBottom:10,marginTop:10}}



                >
              <Translate value="submit" />
              </Button>



            </div>

            <div className="col-6" >
              <Button id="updateprofile-submit" type="primary"  className="login-form-button bt-close"
              disabled={this.state.loadingOtp}
              style={{
                width: '100%',
                background: '#f89932',
                borderColor: '#f89932',marginBottom:10,marginTop:10}}
                onClick={()=>{
                  this.resendOTPmobile({cmd:1,mobileNo:this.state.itemMobile.mobileNo})
                }}>
              <Translate value="resendOtp" />
              </Button>


              </div>
            </div>
            </div>
      </div>:null
      }

      </>
      :<>
      <Form.Item>
        {/* {mobileData.mobileNo } */}
        {getFieldDecorator('mobileNo', {
          initialValue: this.state.mobileNo || "",
          rules: [
            {required: true, message: DictionaryService.msg("vMobile",this.props.locale) },
            {
              pattern:/^[0][0-9]{9}$/,
              message:  <Translate value="vMobile2"/>,
            },
          ],

        })(
          <Grid container spacing={3} alignItems="flex-end">
          <Grid item  xs={1}>
          <div className="sz-icons"><div className="icon mobile"></div></div>
          </Grid>
          <Grid item  xs={11}>
            <TextField id="input-with-icon-grid" label={DictionaryService.msg("mobile",this.props.locale)}  style={{width:  '100%'}}
            disabled={this.state.disabled}
            value={this.state.mobileNo}
            onChange={(e)=>{
              this.setState({
                mobileNo:e.target.value
              })
            }}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
              maxLength:10
            }}
            />
          </Grid>
        </Grid>,
        )}
      </Form.Item>

      <Form.Item>
        <div className="row" style={{margin: '18px 3px'}} >
            <div className="col-12 col-md-12" style={{padding: 0}} >
            <Button id="signin-eregister-submit" type="primary" htmlType="submit" className="login-form-button bt-singin"
            disabled={this.state.loadingOtp}
            style={{
               background: '#f89932',
               borderColor: '#f89932',
               width:  '100%',
               borderRadius: 10}}>
               <Translate value="signIn" />
             </Button>
           </div>
       </div>
     </Form.Item>
     </>}
      </Form>

      {
        this.state.typeMobile === ''?<>

      <div className="col-md-12">
      <Form.Item>
            <FormControlLabel
              label={DictionaryService.msg("msg_keep_logged_in",this.props.locale)}
              control={<Checkbox
              color="white"
              checked={this.state.remember}
              onChange={()=>{
                this.setState({remember:!this.state.remember})
                  //this.handleChangeCheckBok('remember')
              }}
              icon={<Lens style={{fontSize: 22 }}/>} checkedIcon={<LensChecked style={{fontSize: 22 }}/>}
            />}
            />
      </Form.Item>
      </div>
      {
        /**
      <div className="col-md-12">
      <Form.Item>
          <FormControlLabel
            label={DictionaryService.msg("msg_accept",this.props.locale)}
            control={<Checkbox
            color="white"
            checked={this.state.conditionSocial}
            onChange={()=>{
              this.setState({conditionSocial:!this.state.conditionSocial})
            }}
            icon={<Lens style={{ fontSize: 22 }}/>} checkedIcon={<LensChecked style={{fontSize: 22 }}/>}
          />}
          />
            <div className="MuiFormControlLabel-root">
            <a
              onClick={() => {
                this.setState({ conditionVisible: true });
              }}
              style={{
                cursor: "pointer",
                textDecoration:'underline',
                color: '#F89932',
                padding: 10,
                position: 'relative',
                top: 3

              }}
            >
              <Translate value="msg_term_of_use" />
            </a>
            </div>
      </Form.Item>
      </div>
      */}
      </>
      :null}

    {
      this.props.social.length > 0 &&
      (this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.displayLoginForm === 1) &&
      this.props.loginmode !== 'mobile'
      ?
      <div className="-col-12" >
      <Button id="sign-in-free" type="primary" className="login-form-button bt-singin-other" style={{marginBottom: 14,  marginTop: 25,width:  '100%'}}
          onClick={()=>{
            this.props.changeLoginmode('social')
            this.initGetUser()
            // try {
            //   if(localStorage.getItem("user-e-register")){
            //     let decryptedBytes = AES.decrypt(localStorage.getItem("user-e-register"),"szlogin");
            //     let decryptedString = decryptedBytes.toString(  CryptoJS.enc.Utf8);
            //     let user = JSON.parse(decryptedString  || "");
            //     this.setState({userRemember:user})
            //   }
            // } catch (error) {
            // }
          }}>
         <Translate value="loginForFree" />
      </Button>
      </div>
      :null
    }

    </div>
    </div>
  }

  BasicForm(){
    const { getFieldDecorator } = this.props.form;
    let { fieldsLogin } = this.state
    console.log("this.state.username",this.state.username);

    return  <div className="row">
      <div className="col-md-12 header-text">
        {DictionaryService.msg("LoginWithWserCard",this.props.locale)}
      </div>

    <div className="col-md-12">
    <Form onSubmit={(event)=> {
          event.preventDefault();
          this.props.form.validateFields(async (err, values) => {
            if (!err){
              //this.props.OnSubmitLogin({...this.state,...values});
              let vals = {...this.state,...values,username:this.maskOnlyUsername(values.username),password:this.maskOnlyUsername(values.password)}
              this.props.OnSubmitLogin(vals);
            }
          });
        }}
        className="login-form"
        style={{marginTop:8,marginBottom:2}}
        autoComplete="off">


    <Form.Item >
          {getFieldDecorator('username', {
            initialValue:this.state.username || "",
            rules: [{
              required: true, message: DictionaryService.msg("vUsername",this.props.locale)
            },],
          })(
            <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
            <div className="sz-icons"><div className="icon username"></div></div>
            </Grid>
            <Grid item  xs={11}>
              <TextField id="input-with-icon-grid" label={DictionaryService.msg("username",this.props.locale)}  style={{width:  '100%'}}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                }
              }}
              value={this.state.username}
              onChange={(e)=>{
                this.setState({username:this.maskOnlyUsername(e.target.value)},()=>{})

              }}
              />
            </Grid>
          </Grid>,
          )}
        </Form.Item>

        <Form.Item >
          {getFieldDecorator('password', {
            initialValue:this.state.password || "",
            rules: [
              { required: true, message: DictionaryService.msg("vPassword",this.props.locale) }
            ],
          })(
            <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
              <div className="sz-icons"><div className="icon password"></div></div>
            </Grid>
            <Grid item  xs={11}>
              <TextField id="input-with-icon-grid"  type="password" label={DictionaryService.msg("password",this.props.locale)}  style={{width:  '100%'}}
              inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              }
            }}
            value={this.state.password}
            onChange={(e)=>{

              this.setState({password:this.maskOnlyUsername(e.target.value)},()=>{})

            }}
              disabled={this.state.disabled}/>
            </Grid>
          </Grid>,
          )}
        </Form.Item>

        <Form.Item  style={{marginTop:15}}>

        <Button id="signin-submit" type="primary" htmlType="submit" className="login-form-button bt-singin" style={{
          width:  '100%;',marginTop: 10}}
         >
            {DictionaryService.msg("singIn",this.props.locale)}
          </Button>


        </Form.Item>

      </Form>

      <div className="col-md-12">
      <Form.Item>
            <FormControlLabel
              label={DictionaryService.msg("msg_keep_logged_in",this.props.locale)}
              control={<Checkbox
              color="white"
              checked={this.state.remember}
              onChange={()=>{
                this.setState({remember:!this.state.remember})
                  //this.handleChangeCheckBok('remember')
              }}
              icon={<Lens style={{fontSize: 22 }}/>} checkedIcon={<LensChecked style={{fontSize: 22 }}/>}
            />}
            />
      </Form.Item>
      </div>
      {/**
      <div className="col-md-12">
      <Form.Item>
          <FormControlLabel
            label={DictionaryService.msg("msg_accept",this.props.locale)}
            control={<Checkbox
            color="white"
            checked={this.state.conditionSocial}
            onChange={()=>{
              this.setState({conditionSocial:!this.state.conditionSocial})
            }}
            icon={<Lens style={{ fontSize: 22 }}/>} checkedIcon={<LensChecked style={{fontSize: 22 }}/>}
          />}
          />
            <div className="MuiFormControlLabel-root">
            <a
              onClick={() => {
                this.setState({ conditionVisible: true });
              }}
              style={{
                cursor: "pointer",
                textDecoration:'underline',
                color: '#F89932',
                padding: 10,
                position: 'relative',
                top: 3

              }}
            >
              <Translate value="msg_term_of_use" />
            </a>
            </div>
      </Form.Item>
      </div>
      */}


    {
      this.props.social.length > 0 &&
      (this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.displayLoginForm === 1) && this.props.loginmode !== 'mobile'
      ?
      <div className="-col-12" >
      <Button id="sign-in-free" type="primary" className="login-form-button bt-singin-other" style={{marginBottom: 14,  marginTop: 25,width:  '100%'}}
          onClick={()=>{
            this.props.changeLoginmode('social')
              this.initGetUser()
            // try {
            //   if(localStorage.getItem("user-e-register")){
            //     let decryptedBytes = AES.decrypt(localStorage.getItem("user-e-register"),"szlogin");
            //     let decryptedString = decryptedBytes.toString(  CryptoJS.enc.Utf8);
            //     let user = JSON.parse(decryptedString  || "");
            //     this.setState({userRemember:user})
            //   }
            // } catch (error) {
            // }
          }}>
          {
            this.props.specialTheme === 1?<>
            <Translate value="new_users" />
          </>:<>
            <Translate value="loginForFree" />
          </>
        }

      </Button>
      </div>
      :null
    }

    </div>
    </div>
  }

  eregisterForm(){
        return  <div style={{marginTop:30}}>
        {/*      */}
        <SocialLoginForm
            userRemember={this.state.userRemember}
            clearUserRemember={this.clearUserRemember}
            changePage={this.changePage}
            goRegister={(r)=>{this.goRegister(r)}}
            handleSubmitERegister={this.handleSubmitERegister}
            remember={this.state.remember}
            condition={this.state.conditionSocial}

            setRemember={()=>{
              this.setState({remember:!this.state.remember})
            }}
            setCondition={()=>{
              this.setState({conditionSocial:!this.state.conditionSocial})
            }}
            setConditionVisible={()=>{
              this.setState({conditionVisible:!this.state.conditionVisible})
            }}

            handleSubmitERegisterChecklogin={this.handleSubmitERegisterChecklogin}
            rememberSocial={this.state.rememberSocial}
            conditionSocial={this.state.conditionSocial}
            buildingInfo={this.props.buildingInfo}
            loginSocial={this.loginSocial}
        />

        </div>
  }

  SocialForm(){
    // console.log("this.props.social",this.props.social)
    const menu = <Menu style={{width:260}}
    onClick={async(e)=>{
      console.log(e.key)
      if(e.key === '0'){
        // if (this.state.conditionSocial == false) {
        //   if (this.props.locale === "EN") {
        //    Notification.error({
        //       message:"",description:`You must accept the usage agreement by clicking on the checkbox below the password form to login.`
        //     });
        //   } else {
        //     Notification.error({
        //       message:"",description:"ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้",
        //     });
        //   }
        //   return false;
        // }
        //pXb63ixrfJ8pzvI9uiHNJpwZ0VW1grC5
        console.log("====:::",this.state.userRemember)
        let data:any = await this.handleSubmitERegister(this.state.userRemember)
        console.log("data",data)

        let d = data.data ? data.data : {};
        let logindata:any = {};
        if(d.logindata && d.logindata.res && d.logindata.res.resultData){
          logindata = d.logindata.res.resultData
        }
        console.log("====:::",d)
        console.log("ReactGA.event d",d,logindata,data.resultCode , data.resultCode[0])
        let msg = (data.resultCode && data.resultCode[0] === "2" ? data.resultDescription : ( d.msg ? d.msg : (d.msgE?d.msgE:d.pageError) ) )
        let label = (data.resultCode && data.resultCode[0] === "2"?"Successfully":"Error") +" : "+d.resultCode+" $ "+ msg;
        ReactGA.event({
          category: 'Login Page',
          action: logindata.userType?logindata.userType:"",
          label: label
        });
        console.log("ReactGA.event",{
          category: 'Login Page',
          action: logindata.userType?logindata.userType:"",
          label: label
        },logindata,data)


        if(data.resultCode !=="20000" && data.resultCode[0] === "2"){
          let msg = data.errorMessage
          if(data.resultCode ==="20001"){
            msg = (i18n[this.props.locale]?i18n[this.props.locale].emailNotConfirmed:"")  + data.errorMessage
          }
          Notification.success({
            message:"",
            description:msg,
            onCancel:()=>{
              if(browserName === 'WebKit') {
                // window.location.href = window.location.origin + window.location.search +"&reload=true"
              }
            }
          });
          //browserName
        }else if(data.resultCode[0] === "4"){

          Notification.confirm({
          message:"",
          resultCode:data.resultCode,
          okText:this.props.locale==='EN'?'Confirm':'ยืนยัน',
          cancelText:this.props.locale==='EN'?'Close':'ปิด',
          description: <div>

              <div  style={{marginTop:8,fontSize: 13}}>
                  {i18n[this.props.locale].emailNotConfirmed1} {this.state.userRemember.username}
                </div>
              <div  style={{marginTop:8,fontSize: 13,}}>
                  {d.msgE}
              </div>
              <div  style={{marginTop:8,fontSize: 13,}}>
                {i18n[this.props.locale].emailNotConfirmed3}
              </div>

          </div>,
          onOk:(close)=>{

                    console.log("this.props.registerUser",{email:this.state.userRemember.username})
                    this.goRegister({email:this.state.userRemember.username})

            close()
          }
          });

        }else if(data.msgE){

         Notification.error({
           message:"",
           description:data.msgE,
           resultCode:data.resultCode
         });
       }

       if(!this.state.rememberSocial){
        // console.log("remember",this.props.remember)
        // this.clearUserRemember()
       }

      }else if(e.key === '1'){
        this.clearUserRemember()
      }
    }}
    >
      <Menu.Item key="0" >
      <Icon type="user" />{this.state.userRemember.username}
      </Menu.Item>
      <Menu.Item key="1">
      <Icon type="unlock" />
       Use another account
      </Menu.Item>
    </Menu>;


    return  <div style={{marginTop:30}}>

  {(this.state.loadingFb)? <div id="login_" > <div style={{ paddingTop: 0, display: 'table', margin: '0 auto' }}> <div className="loader"></div> </div> </div>:null}
  {(this.props.social.indexOf(2) > -1) && !this.state.loadingFb?<div>
        {this.state.facebookData?null:null}
          <div className="">
            <div  className="sz-bt fb" onClick={()=>{ this.loginFB() }}>
                <div className="icon">
                    <div className="facebook-icon"/>
                </div>
                <div className="body">
                <div className="text-full">
                  {this.state.facebookData?<> <Translate value="facebookLoginWith" /> {this.state.facebookData.name}</>:<> <Translate value="loginWithFacebook" />  </>}
                  </div>
              </div>
            </div>
          </div>
      </div>:null}
      {/*      */}
      {(this.props.social.indexOf(3) > -1) && !this.state.loadingFb?<div>
            {this.state.facebookData?null:null}
              <div className="">
                <div  className="sz-bt line" onClick={()=>{ this.loginLine() }}>
                    <div className="icon">
                        <div className="line-icon"/>
                    </div>
                    <div className="body">
                    <div className="text-full">
                      <> <Translate value="loginWithLine" />  </>
                      </div>
                  </div>
                </div>
              </div>
          </div>:null}


          {(this.props.social.indexOf(0) > -1) && !this.state.loadingFb?<div>
               <div className="">
                    <div  className="sz-bt mobile" onClick={()=>{
                      this.loginMobile()
                    }}>
                        <div className="icon">
                            <div className="mobile-icon"/>
                        </div>
                        <div className="body">
                        <div className="text-full">
                          <> <Translate value="loginWithMobile" />  </>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              :null}



        <div style={this.state.loadingFb ? {display: 'none' } : { }}>
        {/*
        {(this.props.social.indexOf(1) > -1)?
        <SocialLoginForm
            userRemember={this.state.userRemember}
            clearUserRemember={this.clearUserRemember}
            changePage={this.changePage}
            goRegister={()=>{this.goRegister()}}
            handleSubmitERegister={this.handleSubmitERegister}
            rememberSocial={this.state.rememberSocial}
            conditionSocial={this.state.conditionSocial}
            remember={this.state.remember}
            buildingInfo={this.props.buildingInfo}
        />:null}
        */}

        {(this.props.social.indexOf(1) > -1)?<>
        {this.state.userRemember && this.state.userRemember.username?
            <Dropdown overlay={menu} trigger={['click']}>
              <div  className="sz-bt eregis">
                  <div className="icon">
                        <div className="email-icon"/>
                  </div>
                  <div className="body">
                  <div className="text">
                    <div>{this.state.userRemember.username}</div>
                  </div>
                  <div className="end">
                    <Icon type="user"style={{padding: '13px 0px'}} />
                  </div>
                  </div>

            </div>
          </Dropdown>:
           <div  className="sz-bt eregis" onClick={()=>{
             this.loginEregiter()
            //this.setState({ eregiter:!this.state.eregiter})
          }}>
                <div className="icon">
                    <div className="email-icon"/>
                </div>
                <div className="body">
                <div className="text">
                  <Translate value="loginWithERegister" />
                  </div>
                  <div className="end">
                  {/*  <div className="arrow-icon"></div> */}
                  </div>
                  </div>
            </div>
        }
        </>:null}






        <div style={{margin: ' 4px 0px 0px 0px'}}>
        <Form.Item >
            <FormControlLabel
            label={DictionaryService.msg("rememberMe",this.props.locale)}
             control={<Checkbox
             color="white"
             checked={this.state.rememberSocial}
             onChange={()=>{
              this.setState({rememberSocial:!this.state.rememberSocial})
             }}
             icon={<Lens style={{fontSize: 22 }}/>} checkedIcon={<LensChecked style={{fontSize: 22 }}/>}
            />}
            style={{marginLeft: 0}}
           />
         </Form.Item>
         </div>
         {/**
         <div style={{margin: '10px 0px 10px'}}>
            <Form.Item>
                <FormControlLabel
                  label={DictionaryService.msg("msg_accept",this.props.locale)}
                  control={<Checkbox
                  color="white"
                  checked={this.state.conditionSocial}
                  onChange={()=>{
                    this.setState({conditionSocial:!this.state.conditionSocial})
                 }}
                  icon={<Lens style={{ fontSize: 22 }}/>} checkedIcon={<LensChecked style={{fontSize: 22 }}/>}
                />}
                style={{marginLeft: 0}}
                />
                  <div className="MuiFormControlLabel-root">
                  <a
                    onClick={() => {
                      this.setState({ conditionVisible: true });
                    }}
                    style={{
                      cursor: "pointer",
                      textDecoration:'underline',
                      color: '#F89932',
                      padding: 10,
                      position: 'relative',
                      top: 3

                    }}
                  >
                    <Translate value="msg_term_of_use" />
                  </a>
                  </div>
            </Form.Item>
            </div>
            */}
            {
              this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.displayLoginForm === 2?
              <div style={{margin: '10px 0px 0px'}}>
              <Button id="sign-in-free" type="primary" className="login-form-button bt-singin-other" style={{marginBottom: 14,  marginTop: 25,width:  '100%'}}
              onClick={()=>{
                this.props.changeLoginmode('default')
              }}>
                 <Translate value="loginForNotFree" />
              </Button>
              </div>:null
            }
    </div>


    {(this.state.loading)? <div id="login_" > <div style={{ paddingTop: 0, display: 'table', margin: '0 auto' }}> <div className="loader"></div> </div> </div>:null}
    <div style={!this.state.loading ? {} : { display: 'none' }}>
  </div>
  </div>
  }

  FormUI(){

    return <Switch>
                  <Route
                      path={`/register`}
                      render={() => (
                        <RegisterForm changePage={this.changePage}
                          registerUser={this.state.registerUser}
                          registerAction={this.state.registerAction}
                          submitRegister={this.submitRegister}
                          submitRegisterMobile={this.submitRegisterMobile}
                          buildingInfo={this.props.buildingInfo}
                          setRegisterMobileData={this.setRegisterMobileData}
                          facebookAuth={this.state.facebookAuth}
                          enableFacebookUserLikes={this.state.enableFacebookUserLikes}
                          />
                      )}
                        />
                  <Route
                        path={`/forgotpassword`}
                        render={() => (
                          <ForgotpasswordForm changePage={this.changePage}
                            submitForgotPassword={this.submitForgotPassword}
                            buildingInfo={this.props.buildingInfo}
                              />
                        )} />
                 <Route
                      path={`/`}
                      render={() => (
                        this.loginForm()
                      )}
                    />
</Switch>

  }

  loginForm(){
    return <>
    {
    <div className="">
       <div>
       <div >
        <div  id="login-" className="bg_login login"  >
          {
            this.props.loginmode === 'social' && this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.displayLoginForm === 1?
              <div className="bt-sz-back fix" onClick={()=>this.props.changeLoginmode("default")} >
              <Translate value="back" />
            </div>:null
          }

          {
            this.props.loginmode === 'default' && this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.displayLoginForm === 2?
              <div className="bt-sz-back fix" onClick={()=>this.props.changeLoginmode("social")} >
              <Translate value="back" />
            </div>:null
          }

          {
            this.props.loginmode === 'mobile' ?
              <div className="bt-sz-back fix" onClick={()=>{
                this.setState({typeMobile:''})
                this.props.changeLoginmode("social")}} >
              <Translate value="back" />
            </div>:null
          }

          {
            this.props.loginmode === 'eregister' ?
              <div className="bt-sz-back fix" onClick={()=>{
                this.setState({typeMobile:''})
                this.props.changeLoginmode("social")}} >
              <Translate value="back" />
            </div>:null
          }



      <div id="form_login"  >
      {this.props.loginmode === 'social'?this.SocialForm():
      <>
      {
          this.props.loginmode === 'mobile'?this.mobileForm():
            <>
            {
                this.props.loginmode === 'eregister'?
                  this.eregisterForm():
                    <>
                    {this.BasicForm()}
                    </>
            }
            </>
      }

      </>
    }
      </div>
    </div>
    </div>
    </div>
    </div>
    }
    </>
  }

  render() {
    let buildingInfo = this.props.buildingInfo && this.props.buildingInfo.data ? this.props.buildingInfo.data :{};
    // console.log("buildingInfo::data2",buildingInfo)
    return (
      <div>
        <div >
        {this.FormUI()}
        </div>

        <Modal
                 title=""
                 visible={this.state.modalFbLikeVisible}
                 onOk={() => this.setModalFbVisible(false)}
                 footer={null}
                 width={320}
                 onCancel={() => {
                    this.setState({modalFbLikeVisible:false})
                 }}
               >
               {
                 this.state.fbAppId && this.state.facebookAuth.accessToken &&
                 this.props.buildingInfo && this.props.buildingInfo.data &&
                 this.props.buildingInfo.data.enableLikePage &&
                 this.props.buildingInfo.data.fbPageRef?
                 <div className={'facebooklike '+(false?"disabled":"")} >
                 <div>
                 {DictionaryService.msg("v_facebook_like",this.props.locale)}
                 </div>
                 <FacebookProvider appId={this.state.fbAppId}>
                    {/*<Like href={"https://www.facebook.com/"+this.props.buildingInfo.data.fbPageRef} colorScheme="dark" showFaces width="300" /> */}
                    <Page href={"https://www.facebook.com/"+this.props.buildingInfo.data.fbPageRef} tabs=""  height={200}  width="300"/>
                  </FacebookProvider>
                  </div>:null
               }

               <Button id="signin-submit" type="primary" onClick={()=>{
                 this.setModalFbVisible(false)
               }} className="login-form-button bt-singin" style={{
                 width:  '100%',marginTop:10}}
                >
                   {DictionaryService.msg("signIn",this.props.locale)}
                 </Button>

               </Modal>

        <Modal
          title={this.props.locale==='EN'?"Terms of Use":"ข้อตกลงการใช้งาน"}
          visible={this.state.conditionVisible}
          footer={null}
          onCancel={() => {
            this.setState({ conditionVisible: false });
          }}
          width={720}
          mask={true}
        >
          <Agreement  locale={this.props.locale} buildingInfo={buildingInfo}
          />
        </Modal>

      </div>
    );
  }
}


const setOTPData = (res)=>{
  return actions.setOTPData(res)
}
const mapDispatchToProps = {
  setOTPData,
}
const mapStateToProps: any = (state: any) => ({
});

const LoginFormPage:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(LoginForm));
export default LoginFormPage;
